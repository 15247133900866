import React, { useState } from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Button } from '@material-ui/core';

import { useAppSelector } from 'hooks';
import { JobManager } from 'models';

import Page from 'components/Page';
import ModificationDetails from 'components/ModificationDetails';

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        root: {
            display: 'grid',
            gridTemplateRows: 'auto',
        },
        container: {
            margin: spacing(1),
        },
        button: {
            padding: spacing(1),
        },
        buttonContainer: {
            padding: spacing(1),
            display: 'grid',
            alignItems: 'end',
        },
    })
);

interface Props extends RouteComponentProps<{ calc?: string }> {
    onAddNewModification: () => void;
    onRemoveModification: (index: number) => void;
    onSubmitJob: () => Promise<JobManager.Job | null>;
    job: JobManager.NewJob;
}

const JobNewModificationSummaryPage: React.FunctionComponent<Props> = ({
    onRemoveModification,
    onAddNewModification,
    onSubmitJob,
    job,
    history,
}) => {
    const classes = useStyles();

    const { fields } = useAppSelector((state) => state.fields);

    const [loading, setLoading] = useState(false);

    const isJobValid = job.name && job.type;

    return (
        <Page
            title="Select Features"
            subtitle="Select a Criteria for the initial dataset"
            loading={loading}
            footer={
                <Button
                    className={classes.button}
                    variant="contained"
                    disabled={!isJobValid && loading}
                    onClick={async () => {
                        setLoading(true);
                        const createdJob = await onSubmitJob();
                        setLoading(false);
                        if (createdJob) history.push(`/job/${createdJob.id}`);
                    }}
                >
                    Submit Job
                </Button>
            }
        >
            <div className={classes.root}>
                <div className={classes.container}>
                    {job.modifications?.map((mod, idx) => (
                        <ModificationDetails
                            key={`${mod.where}`}
                            mod={mod}
                            fields={fields}
                            onRemove={() => onRemoveModification(idx)}
                        />
                    ))}
                </div>

                <div className={classes.buttonContainer}>
                    <Button className={classes.button} variant="outlined" onClick={onAddNewModification}>
                        Add New Modification
                    </Button>
                </div>
            </div>
        </Page>
    );
};

export default JobNewModificationSummaryPage;
