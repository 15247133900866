import React, { useState } from 'react';

import { RouteComponentProps } from 'react-router-dom';

import Page from 'components/Page';
import ProgressListItem from 'components/ProgressListItem';
import ConfirmDialog from 'components/ConfirmDialog';

const listItems = [
    {
        primaryText: 'Job Details',
        secondaryText: 'Enter title and description',
        path: 'details',
    },
    {
        primaryText: 'Select Feature Set',
        secondaryText: 'The Criteria for the initial Dataset',
        path: 'features',
    },
    {
        primaryText: 'Build Modifications',
        secondaryText: 'A set of modifications to apply to the data',
        path: 'modifications',
    },
];

interface Props extends RouteComponentProps<{ calc?: string }> {
    completed: Record<string, boolean>;
    onCancelNewJob: () => void;
}

const JobNewProgress: React.FunctionComponent<Props> = ({ history, match, location, completed, onCancelNewJob }) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const calculatorId = match.params?.calc;

    const step = location.pathname.split('/')[3] ?? 'details';

    return (
        <Page
            title="New Job"
            subtitle="Select a Calculation to create"
            onBackClick={() => {
                setConfirmOpen(true);
            }}
        >
            {listItems.map(({ primaryText, secondaryText, path }) => (
                <ProgressListItem
                    key={path}
                    primaryText={primaryText}
                    secondaryText={secondaryText}
                    highlighted={step === path}
                    completed={completed?.[path] ?? false}
                    onClick={() => {
                        history.push(`/new/${calculatorId}/${path}`);
                    }}
                />
            ))}
            <ConfirmDialog
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                message="Going back will mean you lose your progress"
                onConfirm={() => {
                    onCancelNewJob();
                }}
            />
        </Page>
    );
};

export default JobNewProgress;
