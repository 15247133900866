import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        root: {
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto',
            padding: `${spacing(2)}px ${spacing(1)}px`,
            borderBottom: '1px solid #00000022',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#f2f2f2',
            },
        },
        statusIcon: {
            display: 'grid',
            placeItems: 'center',
            padding: `${spacing(1)}px ${spacing(2)}px`,
        },
        details: {
            padding: `${spacing(1)}px ${spacing(1)}px`,
        },
        actionIcon: {
            display: 'grid',
            placeItems: 'center',
            padding: `${spacing(1)}px ${spacing(2)}px`,
        },
        icon: {
            fontSize: 32,
        },
        mainText: {},
        secondaryText: {},
    })
);

interface Props {
    actionIcon: JSX.Element;
    statusIcon: JSX.Element;
    onClick?: () => void;
    primaryText: string;
    secondaryText: string;
    style?: React.CSSProperties;
    className?: string;
}

const JobListItem: React.FunctionComponent<Props> = ({
    actionIcon,
    statusIcon,
    onClick,
    primaryText,
    secondaryText,
    style,
    className,
}) => {
    const classes = useStyles();

    return (
        <div
            style={style}
            className={`${classes.root} ${className ?? ''}`}
            onClick={() => {
                if (onClick) onClick();
            }}
            onKeyPress={(event) => {
                if (event.key === 'Enter' && onClick) onClick();
            }}
            role="button"
            tabIndex={0}
        >
            <div className={classes.statusIcon}>{statusIcon}</div>
            <div className={classes.details}>
                <div className={classes.mainText}>
                    <Typography variant="subtitle1">{primaryText}</Typography>
                </div>
                <div className={classes.secondaryText}>
                    <Typography variant="subtitle2">{secondaryText}</Typography>
                </div>
            </div>
            <div className={classes.actionIcon}>{actionIcon}</div>
        </div>
    );
};

export default JobListItem;
