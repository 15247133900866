import { FieldIds, UnprocessedField } from 'models/query';

export enum FieldsEnum {
    'query_geom_id',
    'process_year',
    'process_yr_version',
    'l1_suburb',
    'l1_postcode',
    'l2_lga_name',
    'l3_rms_region_dis',
    'l3_rom_region',
    'l4_sed_name',
    'l5_fed_name',
    'l6_precinct',
    'l8_urban_rural',
    'l7_street',
    'l7_section_id',
    'rep_seg_id',
    'l7_subnetwork_rank',
    'l7_road_class',
    'l7_admin_class',
    'l7_functional_type',
    'l7_speed',
    'l7_speed_truck_bus',
    'l7_road_number',
    'l7_link_number',
    'l7_lcway_code',
    'l7_classified_rd_flag',
    'l7_raf_segment',
    'l7_surface_type',
    'l7_cw_code_altd',
    'l7_cr_tr_cw_dsc',
    'l7_cr_tr_geo_dsc',
    'l7_crossover_status',
    'l7_w_ra_id',
    'l7_cent_x_wgs84',
    'l7_cent_y_wgs84',
    'l7_length',
    'q1_coder_name',
    'q1_coding_date',
    'q1_survey_date',
    'q1_reference_id',
    'q1_road_name',
    'q1_ausrap_section_id',
    'q1_chainage',
    'q1_point_distance',
    'q1_latitude',
    'q1_longitude',
    'q1_location_desc',
    'q1_comments',
    'q1_carriageway',
    'q1_upgrade_cost_cat',
    'q1_mc_observed_flow',
    'q1_pc_observed_flow',
    'q1_ped_obs_fl_across',
    'q1_ped_obs_fl_along_dr_side',
    'q1_ped_obs_fl_along_pass_side',
    'q1_land_use_dr_side',
    'q1_land_use_pass_side',
    'q1_u_r_area',
    'q1_posted_speed',
    'q1_mc_speed',
    'q1_trk_speed',
    'q1_diff_speed',
    'q1_median_type',
    'q1_centline_atlm',
    'q1_rdside_obj_dr_side_dist',
    'q1_rdside_obj_dr_side',
    'q1_rdside_obj_pass_side_dist',
    'q1_rdside_obj_pass_side',
    'q1_shld_atlm',
    'q1_pvd_shld_drv_side',
    'q1_pvd_shld_pass_side',
    'q1_int_type',
    'q1_int_channelisation',
    'q1_intsecting_rd_volume',
    'q1_int_quality',
    'q1_prop_access',
    'q1_no_of_lanes',
    'q1_lane_width',
    'q1_curve_radius',
    'q1_curve_quality',
    'q1_vertical_grade',
    'q1_rd_condition',
    'q1_skid_resistance',
    'q1_delineation',
    'q1_st_lighting',
    'q1_ped_cross_faci_survyd_rd',
    'q1_ped_cross_quality',
    'q1_ped_cross_faci_side_rd',
    'q1_ped_fencing',
    'q1_traffic_calming',
    'q1_parking',
    'q1_footpath_dr_side',
    'q1_footpath_pass_side',
    'q1_service_rd',
    'q1_faci_mc',
    'q1_faci_pc',
    'q1_roadworks',
    'q1_sight_dist',
    'q1_veh_flow_aadt',
    'q1_mc_percent',
    'q1_ped_peak_fl_across_rd',
    'q1_ped_peak_fl_along_dr_side',
    'q1_ped_peak_fl_along_pass_side',
    'q1_pc_peak_fl',
    'q1_op_speed_85th',
    'q1_op_speed_mean',
    'q1_veh_readable_rd',
    'q1_veh_rating_policy_targ',
    'q1_mc_rating_policy_targ',
    'q1_ped_rating_policy_targ',
    'q1_pc_rating_policy_targ',
    'q1_a_fatal_grwth_multplr',
    'q1_sch_zone_warning',
    'q1_sch_zone_cross_supvsr',
    'q1_sfc_in_out_wp_cat',
    'q1_sfc_diff_cat',
    'q1_smoothed_section_id',
    'q1_veh_srs_ro_loc_dr_side',
    'q1_veh_srs_ro_loc_pass_side',
    'q1_veh_srs_ho_loc',
    'q1_veh_srs_ho_overtaking',
    'q1_veh_srs_intersection',
    'q1_veh_srs_prop_access',
    'q1_veh_srs_totl',
    'q1_veh_srs_totl_smoothed',
    'q1_veh_str_rating_raw',
    'q1_veh_str_rating_smoothed',
    'q1_veh_str_rating_smoothed_deci',
    'q1_mc_srs_ro_loc_drv_side',
    'q1_mc_srs_ro_pass_side',
    'q1_mc_srs_ho_loc',
    'q1_mc_srs_ho_overtaking',
    'q1_mc_srs_intersection',
    'q1_mc_srs_prop_access',
    'q1_mc_srs_along',
    'q1_mc_srs_totl',
    'q1_mc_srs_totl_smoothed',
    'q1_mc_str_rating_raw',
    'q1_mc_str_rating_smoothed',
    'q1_ped_srs_along',
    'q1_ped_srs_crossing_side_rd',
    'q1_ped_srs_crossing_surv_rd',
    'q1_ped_srs_totl',
    'q1_ped_srs_totl_smoothed',
    'q1_ped_str_rating_raw',
    'q1_ped_str_rating_smoothed',
    'q1_pc_srs_along',
    'q1_pc_srs_intersection',
    'q1_pc_srs_ro',
    'q1_pc_srs_totl',
    'q1_pc_srs_totl_smoothed',
    'q1_pc_str_rating_raw',
    'q1_pc_str_rating_smoothed',
    'q3_cr_collective_all_rate',
    'q3_cr_collective_all_band',
    'q3_cr_collective_cas_rate',
    'q3_cr_collective_cas_band',
    'q3_cr_collective_fsi_rate',
    'q3_cr_collective_fsi_band',
    'q3_cr_personal_all_rate',
    'q3_cr_personal_all_band',
    'q3_cr_personal_cas_rate',
    'q3_cr_personal_cas_band',
    'q3_cr_personal_fsi_rate',
    'q3_cr_personal_fsi_band',
    'q4_intersection_id',
    'q4_local_id',
    'q4_tcs_no',
    'q4_intersection_type',
    'q4_int_no_of_legs',
    'q4_int_crashes_all',
    'q4_int_crashes_casualty',
    'q4_int_crashes_fsi',
    'q4_int_collective_fsi_score',
    'q4_int_collective_fsi_band',
    'q4_int_personal_fsi_score',
    'q4_int_personal_fsi_band',
    'q4_int_loss_band',
    'q5_op_speed_cal_class',
    'p1_cor_future_state',
    'p1_cor_tot_gap',
    'p1_cor_imp',
    'p1_cor_imp_gap',
    'p1_cor_prgm_2',
    'p1_int_future_state',
    'p1_int_prgm_2',
    'c1_rum_same_dirn_crash',
    'c1_rum_overtaking_crash',
    'q6_calculated_speed',
    'q6_desirable',
    'q6_no_deceleration',
    'q6_unacceptable',
    'q6_undesirable',
    'q6_out_of_context_curves',
    'q7_posted_speed_op_speed_diff',
    'q8_ascending_limit',
    'q8_descending_limit',
    'q8_combined_limit',
    'q8_min_radius',
    'q9_aadt_derived',
    'q9_aadt_der_range',
    'q9_vkt_pa',
    'q9_segment_vkt',
    'q9_road_function_vkt',
    'q9_collective_id_vkt',
    'q9_subnetwork_vkt',
    'q9_uts_vkt',
    'r1_mdn_category_left',
    'r1_mdn_category_right',
    'r1_mdn_category_centre',
    'r1_mdn_category_both',
    'r1_mdn_average_width_left',
    'r1_mdn_average_width_right',
    'r1_mdn_average_width_centre',
    'r1_mdn_average_width_both',
    'r1_mdn_side_left',
    'r1_mdn_side_right',
    'r1_mdn_side_centre',
    'r1_mdn_side_both',
    'r2_shd_cs_position_left',
    'r2_shd_cs_position_right',
    'r2_shd_sealed_width_left',
    'r2_shd_unsealed_width_left',
    'r2_shd_sealed_width_right',
    'r2_shd_unsealed_width_right',
    'r3_bar_side_left',
    'r3_bar_side_right',
    'r3_bar_side_centre',
    'r3_bar_side_both',
    'r3_bar_barrier_type_left',
    'r3_bar_barrier_type_right',
    'r3_bar_barrier_type_centre',
    'r3_bar_barrier_type_both',
    'r4_lne_cross_sect_pos_ct1',
    'r4_lne_cross_sect_pos_ct2',
    'r4_lne_cross_sect_pos_ct3',
    'r4_lne_cross_sect_pos_ct4',
    'r4_lne_cross_sect_pos_ct5',
    'r4_lne_cross_sect_pos_ct6',
    'r4_lne_cross_sect_pos_cr1',
    'r4_lne_cross_sect_pos_cr2',
    'r4_lne_cross_sect_pos_cr3',
    'r4_lne_cross_sect_pos_cr4',
    'r4_lne_cross_sect_pos_cr5',
    'r4_lne_cross_sect_pos_cr6',
    'r4_lne_cross_sect_pos_cl1',
    'r4_lne_cross_sect_pos_cl2',
    'r4_lne_cross_sect_pos_cl3',
    'r4_lne_cross_sect_pos_cl4',
    'r4_lne_cross_sect_pos_cl5',
    'r4_lne_cross_sect_pos_cl6',
    'r4_lne_cross_sect_pos_pt1',
    'r4_lne_cross_sect_pos_pt2',
    'r4_lne_cross_sect_pos_pt3',
    'r4_lne_cross_sect_pos_pt4',
    'r4_lne_cross_sect_pos_pt5',
    'r4_lne_cross_sect_pos_pt6',
    'r4_lne_cross_sect_pos_pr1',
    'r4_lne_cross_sect_pos_pr2',
    'r4_lne_cross_sect_pos_pr3',
    'r4_lne_cross_sect_pos_pr4',
    'r4_lne_cross_sect_pos_pr5',
    'r4_lne_cross_sect_pos_pr6',
    'r4_lne_cross_sect_pos_pl1',
    'r4_lne_cross_sect_pos_pl2',
    'r4_lne_cross_sect_pos_pl3',
    'r4_lne_cross_sect_pos_pl4',
    'r4_lne_cross_sect_pos_pl5',
    'r4_lne_cross_sect_pos_pl6',
    'r4_lne_cross_sect_pos_st1',
    'r4_lne_cross_sect_pos_st2',
    'r4_lne_cross_sect_pos_st3',
    'r4_lne_cross_sect_pos_st4',
    'r4_lne_cross_sect_pos_st5',
    'r4_lne_cross_sect_pos_st6',
    'r4_lne_lane_width_pt1',
    'r4_lne_lane_width_pt2',
    'r4_lne_lane_width_pt3',
    'r4_lne_lane_width_pt4',
    'r4_lne_lane_width_pt5',
    'r4_lne_lane_width_pt6',
    'r4_lne_lane_width_pr1',
    'r4_lne_lane_width_pr2',
    'r4_lne_lane_width_pr3',
    'r4_lne_lane_width_pr4',
    'r4_lne_lane_width_pr5',
    'r4_lne_lane_width_pr6',
    'r4_lne_lane_width_pl1',
    'r4_lne_lane_width_pl2',
    'r4_lne_lane_width_pl3',
    'r4_lne_lane_width_pl4',
    'r4_lne_lane_width_pl5',
    'r4_lne_lane_width_pl6',
    'r4_lne_lane_width_ct1',
    'r4_lne_lane_width_ct2',
    'r4_lne_lane_width_ct3',
    'r4_lne_lane_width_ct4',
    'r4_lne_lane_width_ct5',
    'r4_lne_lane_width_ct6',
    'r4_lne_lane_width_cl1',
    'r4_lne_lane_width_cl2',
    'r4_lne_lane_width_cl3',
    'r4_lne_lane_width_cl4',
    'r4_lne_lane_width_cl5',
    'r4_lne_lane_width_cl6',
    'r4_lne_lane_width_cr1',
    'r4_lne_lane_width_cr2',
    'r4_lne_lane_width_cr3',
    'r4_lne_lane_width_cr4',
    'r4_lne_lane_width_cr5',
    'r4_lne_lane_width_cr6',
    'r4_lne_lane_width_st1',
    'r4_lne_lane_width_st2',
    'r4_lne_lane_width_st3',
    'r4_lne_lane_width_st4',
    'r4_lne_lane_width_st5',
    'r4_lne_lane_width_st6',
    'r4_lne_lane_count_tot_pt',
    'r4_lne_lane_count_tot_pr',
    'r4_lne_lane_count_tot_pl',
    'r4_lne_lane_count_tot_ct',
    'r4_lne_lane_count_tot_cl',
    'r4_lne_lane_count_tot_cr',
    'r4_lne_lane_count_tot_st',
    'r4_lne_lane_count_tot_c',
    'r4_lne_lane_count_tot_p',
    'c1_rum_pedestrian_crash',
    'c1_rum_intersection_crash',
    'c1_rum_head_on_crash',
    'c1_rum_runoff_straight_crash',
    'c1_rum_runoff_curve_crash',
    'c1_rum_other_crash',
    'c1_fatal_crash',
    'c1_serious_inj_crash',
    'c1_moderate_inj_crash',
    'c1_minor_inj_crash',
    'c1_towaway_crash',
    't1_tree_count_l',
    't1_tree_count_r',
    't1_tree_dia_max_l',
    't1_tree_dia_max_r',
    't1_tree_dia_min_l',
    't1_tree_dia_min_r',
    't1_tree_dia_mdn_l',
    't1_tree_dia_mdn_r',
    'created_date',
    'created_user',
    'last_edited_date',
    'last_edited_user',
    'objectid',
    'shape',
    'st_length',
}

export const groups: Record<string, FieldIds[]> = {
    'Location-Details': [
        'l1_suburb',
        'l1_postcode',
        'l2_lga_name',
        'l3_rms_region_dis',
        'l3_rom_region',
        'l4_sed_name',
        'l5_fed_name',
        'l6_precinct',
        'l7_street',
        'l7_subnetwork_rank',
        'l7_road_class',
        'l7_functional_type',
        'l7_speed',
        'l7_admin_class',
        'l7_road_number',
        'l7_link_number',
        'l7_lcway_code',
        'l7_surface_type',
        'l7_classified_rd_flag',
        'l8_urban_rural',
        'q9_aadt_derived',
        'q9_aadt_der_range',
    ],
    'AusRAP-General': [
        'q1_road_name',
        'q1_carriageway',
        'q1_upgrade_cost_cat',
        'q1_land_use_dr_side',
        'q1_land_use_pass_side',
        'q1_u_r_area',
    ],
    'AusRAP-Intersections': [
        'q1_int_type',
        'q1_int_channelisation',
        'q1_intsecting_rd_volume',
        'q1_int_quality',
        'q1_prop_access',
    ],
    'AusRAP-Roads-and-Roadsides': [
        'q1_median_type',
        'q1_centline_atlm',
        'q1_rdside_obj_dr_side_dist',
        'q1_rdside_obj_dr_side',
        'q1_rdside_obj_pass_side_dist',
        'q1_rdside_obj_pass_side',
        'q1_shld_atlm',
        'q1_pvd_shld_drv_side',
        'q1_pvd_shld_pass_side',
        'q1_no_of_lanes',
        'q1_lane_width',
        'q1_curve_radius',
        'q1_curve_quality',
        'q1_vertical_grade',
        'q1_rd_condition',
        'q1_skid_resistance',
        'q1_delineation',
        'q1_st_lighting',
        'q1_parking',
        'q1_service_rd',
        'q1_roadworks',
        'q1_sight_dist',
    ],
    'AusRAP-Road-User-Facilities': [
        'q1_ped_cross_faci_survyd_rd',
        'q1_ped_cross_quality',
        'q1_ped_cross_faci_side_rd',
        'q1_ped_fencing',
        'q1_footpath_dr_side',
        'q1_footpath_pass_side',
        'q1_faci_mc',
        'q1_faci_pc',
    ],
    'AusRAP-Speeds': [
        'q1_posted_speed',
        'q1_mc_speed',
        'q1_trk_speed',
        'q1_diff_speed',
        'q1_traffic_calming',
        'q1_op_speed_85th',
        'q1_op_speed_mean',
        'q1_sch_zone_warning',
        'q1_sch_zone_cross_supvsr',
    ],
    'AusRAP-Star-Ratings-Band': ['q1_veh_str_rating_raw', 'q1_veh_str_rating_smoothed'],
    'AusRAP-Star-Ratings-Scores': [
        'q1_veh_srs_ro_loc_dr_side',
        'q1_veh_srs_ro_loc_pass_side',
        'q1_veh_srs_ho_loc',
        'q1_veh_srs_ho_overtaking',
        'q1_veh_srs_intersection',
        'q1_veh_srs_prop_access',
        'q1_veh_srs_totl',
        'q1_veh_srs_totl_smoothed',
    ],
    'AusRAP-Traffic-Flow': [
        'q1_mc_observed_flow',
        'q1_pc_observed_flow',
        'q1_ped_obs_fl_across',
        'q1_ped_obs_fl_along_dr_side',
        'q1_ped_obs_fl_along_pass_side',
        'q1_veh_flow_aadt',
        'q1_mc_percent',
        'q1_ped_peak_fl_across_rd',
        'q1_ped_peak_fl_along_dr_side',
        'q1_ped_peak_fl_along_pass_side',
        'q1_pc_peak_fl',
    ],   
    'Crash-Rates-Severity': [
        'c1_fatal_crash',
        'c1_serious_inj_crash',
        'c1_moderate_inj_crash',
        'c1_minor_inj_crash',
        'c1_towaway_crash'
      ], 
    'Crash-Rates-Rum': [
        'c1_rum_pedestrian_crash',
        'c1_rum_intersection_crash',
        'c1_rum_head_on_crash',
        'c1_rum_same_dirn_crash',
        'c1_rum_overtaking_crash',
        'c1_rum_runoff_straight_crash',
        'c1_rum_runoff_curve_crash',
        'c1_rum_other_crash'
      ],
    'Crash-Rates-Results': [
        'q3_cr_collective_cas_rate',
        'q3_cr_collective_fsi_rate',
        'q3_cr_collective_all_rate',
        'q3_cr_personal_cas_rate',
        'q3_cr_personal_fsi_rate',
        'q3_cr_personal_all_rate',
        'q3_cr_collective_all_band',
        'q3_cr_personal_all_band',
        'q3_cr_collective_cas_band',
        'q3_cr_personal_cas_band',
        'q3_cr_collective_fsi_band',
        'q3_cr_personal_fsi_band',
    ],
    'Curve-Analysis': ['q8_ascending_limit', 'q8_descending_limit', 'q8_combined_limit', 'q8_min_radius'],
    'Corridor-Analysis-Curve': [
        'q6_desirable',
        'q6_no_deceleration',
        // 'q6_straight', // I feel like this should exist
        'q6_unacceptable',
        'q6_undesirable',
        'q7_posted_speed_op_speed_diff',
    ],
    'Intersection-Risk-Model-Counts': [
        'q4_int_crashes_casualty',
        'q4_int_crashes_all',
        'q4_int_crashes_fsi',
    ],
    'Intersection-Risk-Model-Results': [
        'q4_intersection_type',
        'q4_int_no_of_legs',
        'q4_int_collective_fsi_score',
        'q4_int_collective_fsi_band',
        'q4_int_personal_fsi_score',
        'q4_int_personal_fsi_band',
        'q4_tcs_no',
        'q4_int_loss_band',
    ],
    'Strategic-Program': [
        'p1_cor_future_state',
        'p1_cor_tot_gap',
        'p1_cor_imp',
        'p1_cor_imp_gap',
        'p1_cor_prgm_2',
        'p1_int_future_state',
        'p1_int_prgm_2',
    ],
    'Median': [	
        'r1_mdn_category_left',	
        'r1_mdn_category_right',	
        'r1_mdn_category_centre',	
        'r1_mdn_category_both'	
      ],
      'Shoulder': [	
        'r2_shd_sealed_width_left',	
        'r2_shd_unsealed_width_left',	
        'r2_shd_sealed_width_right',	
        'r2_shd_unsealed_width_right'	
      ],	
      'Barrier': [	
        'r3_bar_barrier_type_left',	
        'r3_bar_barrier_type_right',	
        'r3_bar_barrier_type_centre',	
        'r3_bar_barrier_type_both'	
      ],	
      'Lane': [	
        'r4_lne_lane_width_pt1',	
        'r4_lne_lane_width_pt2',	
        'r4_lne_lane_width_pt3',	
        'r4_lne_lane_width_ct1',	
        'r4_lne_lane_width_ct2',	
        'r4_lne_lane_width_ct3',	
        'r4_lne_lane_count_tot_pt',	
        'r4_lne_lane_count_tot_pr',	
        'r4_lne_lane_count_tot_pl',	
        'r4_lne_lane_count_tot_ct',	
        'r4_lne_lane_count_tot_cl',	
        'r4_lne_lane_count_tot_cr',	
        'r4_lne_lane_count_tot_st',	
        'r4_lne_lane_count_tot_c',	
        'r4_lne_lane_count_tot_p'	
      ],
    'Operating-Speed-Model': [
        // 'Q5_OP_SPEED_MIN',
        // 'Q5_OP_SPEED_MAX',
        'q5_op_speed_cal_class',
        // 'Q5_OP_SPEED_CATEGORY',
        'q6_calculated_speed',
    ],
};

export const unprocessedFields: Partial<Record<FieldIds, UnprocessedField>> = {
    l1_suburb: {
        name: 'Suburb',
        input: 'text',
    },
    l1_postcode: {
        name: 'Postcode',
        input: 'typeahead',
        isNumeric: true,
    },
    l2_lga_name: {
        name: 'Local Government Area',
        input: 'typeahead',
    },
    l3_rms_region_dis: {
        name: 'RMS Region',
        input: 'text',
    },
    l3_rom_region: {
        name: 'ROM Region',
        input: 'typeahead',
    },
    l4_sed_name: {
        name: 'State Electoral District',
        input: 'typeahead',
    },
    l5_fed_name: {
        name: 'Federal Electoral District',
        input: 'typeahead',
    },
    l6_precinct: {
        name: 'RMS Precincts',
        input: 'typeahead',
    },
    l8_urban_rural: {
        name: 'Urban/Rural',
        input: 'typeahead',
        values: ['Rural', 'Urban'],
    },
    l7_street: {
        name: 'Road name',
        input: 'text',
    },
    l7_section_id: {
        name: 'Section Id',
    },
    rep_seg_id: {
        name: 'Rep segment Id',
    },
    l7_subnetwork_rank: {
        name: 'Subnetwork rank',
        input: 'typeahead',
    },
    l7_road_class: {
        name: 'Road class',
        input: 'typeahead',
    },
    l7_admin_class: {
        name: 'Admin class',
        input: 'typeahead',
    },
    l7_functional_type: {
        name: 'Functional type',
        input: 'typeahead',
        isNumeric: true,
    },
    l7_speed: {
        name: 'Speed',
        units: ' km/h',
        input: 'typeahead',
    },
    l7_speed_truck_bus: {
        name: 'Bus/Truck Speed',
        units: ' km/h',
        input: 'typeahead',
    },
    l7_road_number: {
        name: 'Road number',
    },
    l7_link_number: {
        name: 'Link number',
    },
    l7_lcway_code: {
        name: 'Carriageway code',
        input: 'typeahead',
    },
    l7_classified_rd_flag: {
        name: 'Classified road flag',
        input: 'typeahead',
        values: ['Classified', 'Unclassified'],
    },
    l7_raf_segment: {
        name: 'RAF Segment',
    },
    l7_surface_type: {
        name: 'Surface Type',
        input: 'typeahead',
    },
    l7_cent_x_wgs84: {
        name: 'Longitude',
    },
    l7_cent_y_wgs84: {
        name: 'Latitude',
    },
    l7_length: {
        name: 'Length',
    },
    q1_coder_name: {},
    q1_coding_date: {},
    q1_survey_date: {
        name: 'Survery Date',
    },
    q1_reference_id: {},
    q1_road_name: {
        name: 'Road name (AusRAP)',
        input: 'text',
    },
    q1_ausrap_section_id: {
        name: 'AusRAP Section Id',
    },
    q1_chainage: {
        name: 'Chainage',
    },
    q1_point_distance: {
        name: 'Point distance',
    },
    q1_latitude: {
        name: 'Latitude',
    },
    q1_longitude: {
        name: 'Longitude',
    },
    q1_location_desc: {
        name: 'Location description',
    },
    q1_comments: {
        name: 'Comments',
    },
    q1_carriageway: {
        name: 'Carriageway',
        input: 'typeahead',
    },
    q1_upgrade_cost_cat: {
        name: 'Upgrade cost',
        input: 'typeahead',
    },
    q1_mc_observed_flow: {
        name: 'Motorcyclist observed flow',
    },
    q1_pc_observed_flow: {
        name: 'Bicyclist observed flow',
    },
    q1_ped_obs_fl_across: {
        name: 'Pedestrian observed flow across the road',
    },
    q1_ped_obs_fl_along_dr_side: {
        name: 'Pedestrian observed flow along road - driver side',
    },
    q1_ped_obs_fl_along_pass_side: {
        name: 'Pedestrian observed flow along road - passenger side',
    },
    q1_land_use_dr_side: {
        name: 'Land use - driver side',
        input: 'typeahead',
    },
    q1_land_use_pass_side: {
        name: 'Land use - passenger side',
        input: 'typeahead',
    },
    q1_u_r_area: {
        name: 'Urban rural area',
        input: 'typeahead',
    },
    q1_posted_speed: {
        name: 'Posted speed limit',
        input: 'typeahead',
    },
    q1_mc_speed: {
        name: 'Motorcyclist speed limit',
        input: 'typeahead',
    },
    q1_trk_speed: {
        name: 'Truck speed limit',
        input: 'typeahead',
    },
    q1_diff_speed: {
        name: 'Differential speed',
        input: 'typeahead',
    },
    q1_median_type: {
        name: 'Median type',
        input: 'typeahead',
    },
    q1_centline_atlm: {
        name: 'Centreline rumble strips',
        input: 'typeahead',
    },
    q1_rdside_obj_dr_side_dist: {
        name: 'Roadside object distance - driver side',
        input: 'typeahead',
    },
    q1_rdside_obj_dr_side: {
        name: 'Roadside object - driver side',
        input: 'typeahead',
    },
    q1_rdside_obj_pass_side_dist: {
        name: 'Roadside object distance - passenger side',
        input: 'typeahead',
    },
    q1_rdside_obj_pass_side: {
        name: 'Roadside object - passenger side',
        input: 'typeahead',
    },
    q1_shld_atlm: {
        name: 'Shoulder rumble strips',
        input: 'typeahead',
    },
    q1_pvd_shld_drv_side: {
        name: 'Paved shoulder - driver side',
        input: 'typeahead',
    },
    q1_pvd_shld_pass_side: {
        name: 'Paved shoulder - passenger side',
        input: 'typeahead',
    },
    q1_int_type: {
        name: 'Intersection type',
        input: 'typeahead',
    },
    q1_int_channelisation: {
        name: 'Intersection channelisation',
        input: 'typeahead',
    },
    q1_intsecting_rd_volume: {
        name: 'Intersecting road volume',
        input: 'typeahead',
    },
    q1_int_quality: {
        name: 'Intersection quality',
        input: 'typeahead',
    },
    q1_prop_access: {
        name: 'Property access',
        input: 'typeahead',
    },
    q1_no_of_lanes: {
        name: 'Number of lanes',
        input: 'typeahead',
    },
    q1_lane_width: {
        name: 'Lane width',
        input: 'typeahead',
    },
    q1_curve_radius: {
        name: 'Curve radius',
        input: 'typeahead',
    },
    q1_curve_quality: {
        name: 'Curve quality',
        input: 'typeahead',
    },
    q1_vertical_grade: {
        name: 'Vertical grade',
        input: 'typeahead',
    },
    q1_rd_condition: {
        name: 'Road condition',
        input: 'typeahead',
    },
    q1_skid_resistance: {
        name: 'Skid resistance/ grip',
        input: 'typeahead',
    },
    q1_delineation: {
        name: 'Delineation',
        input: 'typeahead',
    },
    q1_st_lighting: {
        name: 'Street lighting',
        input: 'typeahead',
    },
    q1_ped_cross_faci_survyd_rd: {
        name: 'Pedestrian facility - surveyed road',
        input: 'typeahead',
    },
    q1_ped_cross_quality: {
        name: 'Pedestrian facility quality',
        input: 'typeahead',
    },
    q1_ped_cross_faci_side_rd: {
        name: 'Pedestrian facility - intersecting road',
        input: 'typeahead',
    },
    q1_ped_fencing: {
        name: 'Pedestrian fencing',
        input: 'typeahead',
    },
    q1_traffic_calming: {
        name: 'Traffic calming/ Speed management',
        input: 'typeahead',
    },
    q1_parking: {
        name: 'Vehicle Parking',
        input: 'typeahead',
    },
    q1_footpath_dr_side: {
        name: 'Footpath - drive side',
        input: 'typeahead',
    },
    q1_footpath_pass_side: {
        name: 'Footpath - passenger side',
        input: 'typeahead',
    },
    q1_service_rd: {
        name: 'Service road',
        input: 'typeahead',
    },
    q1_faci_mc: {
        name: 'Motorcyclist facility',
        input: 'typeahead',
    },
    q1_faci_pc: {
        name: 'Bicycle facility',
        input: 'typeahead',
    },
    q1_roadworks: {
        name: 'Roadworks',
        input: 'typeahead',
    },
    q1_sight_dist: {
        name: 'Sight distance',
        input: 'typeahead',
    },
    q1_veh_flow_aadt: {
        name: 'Vehicle flow AADT',
        isNumeric: true,
    },
    q1_mc_percent: {
        name: 'Motorcyclist %',
        input: 'typeahead',
    },
    q1_ped_peak_fl_across_rd: {
        name: 'Pedestrian peak flow across road',
        input: 'typeahead',
    },
    q1_ped_peak_fl_along_dr_side: {
        name: 'Pedestrian peak flow along road - driver side',
        input: 'typeahead',
    },
    q1_ped_peak_fl_along_pass_side: {
        name: 'Pedestrian peak flow along road - passenger side',
        input: 'typeahead',
    },
    q1_pc_peak_fl: {
        name: 'Bicyclist peak flow',
        input: 'typeahead',
    },
    q1_op_speed_85th: {
        name: 'Operating speed - 85th percentile',
        input: 'typeahead',
    },
    q1_op_speed_mean: {
        name: 'Operating speed - mean',
        input: 'typeahead',
    },
    q1_veh_readable_rd: {
        name: 'Q1_VEH_READABLE_RD',
    },
    q1_veh_rating_policy_targ: {
        name: 'Q1_VEH_RATING_POLICY_TARG',
    },
    q1_mc_rating_policy_targ: {
        name: 'Q1_MC_RATING_POLICY_TARG',
    },
    q1_ped_rating_policy_targ: {
        name: 'Q1_PED_RATING_POLICY_TARG',
    },
    q1_pc_rating_policy_targ: {
        name: 'Q1_PC_RATING_POLICY_TARG',
    },
    q1_a_fatal_grwth_multplr: {
        name: 'Q1_A_FATAL_GRWTH_MULTPLR',
    },
    q1_sch_zone_warning: {
        name: 'School zone signage',
        input: 'typeahead',
    },
    q1_sch_zone_cross_supvsr: {
        name: 'School zone crossing supervisor',
        input: 'typeahead',
    },
    q1_sfc_in_out_wp_cat: {
        name: 'Q1_SFC_IN_OUT_WP_CAT',
    },
    q1_sfc_diff_cat: {
        name: 'Q1_SFC_DIFF_CAT',
    },
    q1_smoothed_section_id: {
        name: 'Q1_SMOOTHED_SECTION_ID',
    },
    q1_veh_srs_ro_loc_dr_side: {
        name: 'Runoff loss of control - drive side (SRS)',
    },
    q1_veh_srs_ro_loc_pass_side: {
        name: 'Runoff loss of control - passenger side (SRS)',
    },
    q1_veh_srs_ho_loc: {
        name: 'Head on loss of control (SRS)',
    },
    q1_veh_srs_ho_overtaking: {
        name: 'Head on overtaking (SRS)',
    },
    q1_veh_srs_intersection: {
        name: 'Intersection (SRS)',
    },
    q1_veh_srs_prop_access: {
        name: 'Property access (SRS)',
    },
    q1_veh_srs_totl: {
        name: 'Combined star rating scores raw (SRS)',
    },
    q1_veh_srs_totl_smoothed: {
        name: 'Combined star rating scores smoothed (SRS)',
    },
    q1_veh_str_rating_raw: {
        name: 'Star ratings raw',
        input: 'typeahead',
    },
    q1_veh_str_rating_smoothed: {
        name: 'Star ratings smoothed',
        input: 'typeahead',
    },
    q1_veh_str_rating_smoothed_deci: {
        name: 'Star ratings smoothed (decimal)',
        input: 'typeahead',
    },
    q4_intersection_type: {
        name: 'Intersection Type',
        input: 'typeahead',
    },
    q4_int_no_of_legs: {
        name: 'Intersection Number of Legs',
        input: 'typeahead',
        isNumeric: true,
    },
    q5_op_speed_cal_class: {
        name: 'OP Speed Cal Class',
        input: 'typeahead',
    },
    q9_aadt_der_range: {
        name: 'AADT Der Range',
        input: 'typeahead',
    },
    objectid: {
        name: 'Object Id',
    },
    c1_rum_same_dirn_crash: {
        name: 'c1 rum same dirn crash',
        isNumeric: true,
    },
    c1_rum_overtaking_crash: {
        name: 'c1 rum overtaking crash',
        isNumeric: true,
    },
    p1_cor_future_state: {
        name: 'Corridor Future State',
        input: 'typeahead',
    },
    p1_cor_tot_gap: {
        name: 'Corridor Gap',
        input: 'typeahead',
    },
    p1_cor_imp: {
        name: 'Corridor Intermediate',
        input: 'typeahead',
    },
    p1_cor_imp_gap: {
        name: 'Corridor Intermediate Gap',
        input: 'typeahead',
    },
    p1_cor_prgm_2: {
        name: 'Corridor Program 2',
        input: 'typeahead',
    },
    p1_int_future_state: {
        name: 'Intersection Future State',
        input: 'typeahead',
    },
    p1_int_prgm_2: {
        name: 'Intersection Program 2',
        input: 'typeahead',
    },
    r1_mdn_category_left: {
        name: 'Median Category Left',
        input: 'typeahead',
    },
    r1_mdn_category_right: {
        name: 'Median Category Right',
        input: 'typeahead',
    },
    r1_mdn_category_centre: {
        name: 'Median Category Centre',
        input: 'typeahead',
    },
    r1_mdn_category_both: {
        name: 'Median Category Both',
        input: 'typeahead',
    },
    r2_shd_sealed_width_left: {
        name: 'Sealed Shoulder Width Left',
        isNumeric: true,
    },
    r2_shd_unsealed_width_left: {
        name: 'Unsealed Shoulder Width Left',
        isNumeric: true,
    },
    r2_shd_sealed_width_right: {
        name: 'Sealed Shoulder Width Right',
        isNumeric: true,
    },
    r2_shd_unsealed_width_right: {
        name: 'Unsealed Shoulder Width Right',
        isNumeric: true,
    },
    r3_bar_barrier_type_left: {
        name: 'Barrier Type Left',
        input: 'typeahead',
    },
    r3_bar_barrier_type_right: {
        name: 'Barrier Type Right',
        input: 'typeahead',
    },
    r3_bar_barrier_type_centre: {
        name: 'Barrier Type Centre',
        input: 'typeahead',
    },
    r3_bar_barrier_type_both: {
        name: 'Barrier Type Both',
        input: 'typeahead',
    },
    r4_lne_lane_width_pt1: {
        name: 'Prescribed Through Lane 1 Width',
        isNumeric: true,
    },
    r4_lne_lane_width_pt2: {
        name: 'Prescribed Through Lane 2 Width',
        isNumeric: true,
    },
    r4_lne_lane_width_pt3: {
        name: 'Prescribed Through Lane 3 Width',
        isNumeric: true,
    },
    r4_lne_lane_width_ct1: {
        name: 'Counter Through Lane 1 Width',
        isNumeric: true,
    },
    r4_lne_lane_width_ct2: {
        name: 'Counter Through Lane 2 WidthT',
        isNumeric: true,
    },
    r4_lne_lane_width_ct3: {
        name: 'Counter Through Lane 3 Width',
        isNumeric: true,
    },
    r4_lne_lane_count_tot_pt: {
        name: 'Prescribed Through Lane Count Total',
        isNumeric: true,
    },
    r4_lne_lane_count_tot_pr: {
        name: 'Prescribed Right Turn Lane Count Total',
        isNumeric: true,
    },
    r4_lne_lane_count_tot_pl: {
        name: 'Prescribed Left Turn Lane Count Total',
        isNumeric: true,
    },
    r4_lne_lane_count_tot_ct: {
        name: 'Counter Through Lane Count Total',
        isNumeric: true,
    },
    r4_lne_lane_count_tot_cl: {
        name: 'Counter Left Turn Lane Count Total',
        isNumeric: true,
    },
    r4_lne_lane_count_tot_cr: {
        name: 'Counter Right Turn Lane Count Total',
        isNumeric: true,
    },
    r4_lne_lane_count_tot_st: {
        name: 'Shared Turn Lane Count Total',
        isNumeric: true,
    },
    r4_lne_lane_count_tot_c: {
        name: 'Prescribed Direction Lane Count Total',
        isNumeric: true,
    },
    r4_lne_lane_count_tot_p: {
        name: 'Counter Direction Lane Count Total',
        isNumeric: true,
    },
    c1_rum_pedestrian_crash: {
        name: 'Pedestrian Crash Count',
        isNumeric: true,
    },
    c1_rum_intersection_crash: {
        name: 'Intersection Crash Count',
        isNumeric: true,
    },
    c1_rum_head_on_crash: {
        name: 'Head On Crash Count',
        isNumeric: true,
    },
    c1_rum_runoff_straight_crash: {
        name: 'Runoff on Straight Crash Count',
        isNumeric: true,
    },
    c1_rum_runoff_curve_crash: {
        name: 'Runoff on Curve Crash Count',
        isNumeric: true,
    },
    c1_rum_other_crash: {
        name: 'Other Crash Count',
        isNumeric: true,
    },
    c1_fatal_crash: {
        name: 'Fatal Crash Count',
        isNumeric: true,
    },
    c1_serious_inj_crash: {
        name: 'Serious Injury Crash',
        isNumeric: true,
    },
    c1_moderate_inj_crash: {
        name: 'Moderate Injury Crash',
        isNumeric: true,
    },
    c1_minor_inj_crash: {
        name: 'Minor Injury Crash',
        isNumeric: true,
    },
    c1_towaway_crash: {
        name: 'Towaway Crash',
        isNumeric: true,
    },
};
