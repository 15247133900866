import { FieldIds, Field, Fields, Query } from 'models/query';

export enum CalculatorsEnum {
    'ausrap',
    'intersection',
    'operating_speed',
}

export type CalculatorId = keyof typeof CalculatorsEnum;

export interface Calculator {
    id: CalculatorId;
    name: string;
    description: string;
    defaultQuery: Query;
    disabled?: boolean;
    aggregatesToDisplay: string[];
    setFieldIds: FieldIds[];
}

export const calculators: Calculator[] = [
    {
        id: 'ausrap',
        name: 'AusRAP Star Ratings',
        description: 'Will recalculate the AusRAP star ratings',
        defaultQuery: {
            where: '(q1_veh_str_rating_raw = 1 OR q1_veh_str_rating_raw = 2 OR q1_veh_str_rating_raw = 4 OR q1_veh_str_rating_raw = 3 OR q1_veh_str_rating_raw = 5)',
            fieldValues: { q1_veh_str_rating_raw: '1 OR 2 OR 4 OR 3 OR 5' },
        },
        aggregatesToDisplay: [
            ...[1, 2, 3, 4, 5].map((i) => `${i} Star Smoothed`),
            ...[1, 2, 3, 4, 5].map((i) => `${i} Star`),
        ],
        setFieldIds: [
            'q1_lane_width',
            'q1_curve_radius',
            'q1_curve_quality',
            'q1_delineation',
            'q1_shld_atlm',
            'q1_rd_condition',
            'q1_vertical_grade',
            'q1_skid_resistance',
            'q1_rdside_obj_dr_side_dist',
            'q1_rdside_obj_dr_side',
            'q1_pvd_shld_drv_side',
            'q1_posted_speed',
            'q1_rdside_obj_pass_side_dist',
            'q1_rdside_obj_pass_side',
            'q1_pvd_shld_pass_side',
            'q1_centline_atlm',
            'q1_median_type',
            'q1_diff_speed',
            'q1_no_of_lanes',
            'q1_int_type',
            'q1_int_quality',
            'q1_st_lighting',
            'q1_sight_dist',
            'q1_int_channelisation',
            'q1_traffic_calming',
            'q1_prop_access',
            'q1_service_rd',
            'q1_veh_flow_aadt',
        ],
    },
    {
        id: 'intersection',
        name: 'Intersection Safety (Not yet implemented)',
        description: 'Will recalculate LOSS and other intersection stuff',
        defaultQuery: {
            where: '',
        },
        disabled: true,
        aggregatesToDisplay: [],
        setFieldIds: [],
    },
    {
        id: 'operating_speed',
        name: 'Operating Speed (Not yet implemented)',
        description: 'Will use lookup tables to recalculate operating speed',
        defaultQuery: {
            where: '',
        },
        disabled: true,
        aggregatesToDisplay: [],
        setFieldIds: [],
    },
];

export const getSetFields = (calculator?: Calculator, fields?: Fields | null) =>
    (calculator?.setFieldIds.map((id) => fields?.[id]).filter((x) => x) as Field[]) ?? [];

export const getById = (id?: string | null) => (id != null ? calculators.find((c) => c.id === id) : undefined);
