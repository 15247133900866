import React from 'react';

import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Link, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'grid',
            gridAutoFlow: 'rows',
            padding: theme.spacing(2),
            placeItems: 'center',
        },
    })
);

const Error404Page: React.FunctionComponent<RouteComponentProps> = ({ location }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h5" color="error">
                Error 404
            </Typography>
            <Typography variant="h6">Page not found: {location.pathname}</Typography>
            <Link component={RouterLink} to="/">
                Click here to return home
            </Link>
        </div>
    );
};

export default Error404Page;
