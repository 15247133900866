/* eslint-disable no-await-in-loop */
/* eslint-disable no-debugger */
/* eslint-disable new-cap */
/* eslint-disable no-param-reassign */
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// eslint-disable-next-line @typescript-eslint/ban-types
type LiteralUnion<T extends U, U = string> = T | (U & {});

type VariableKeyType = string; // LiteralUnion<'page_number' | 'date' | 'long_date' | 'total_pages'>;

export enum SupportedPaperSizesEnum {
    'A4',
    'A3',
}

export type SupportedPaperSize = keyof typeof SupportedPaperSizesEnum;

export enum SupportedElementTypesEnum {
    'text',
    'html',
    'map',
    'image',
    'custom_image',
    'none',
}

export type SupportedElementTypes = keyof typeof SupportedElementTypesEnum;

export declare namespace ReportConfig {
    export interface Margin {
        left: number;
        right: number;
        top: number;
        bottom: number;
    }

    export interface X {
        align: 'left' | 'right' | 'center' | 'leftof' | 'rightof' | 'centerof';
        offset?: number;
        id?: string;
    }

    export interface Y {
        align: 'top' | 'bottom' | 'center' | 'under' | 'over' | 'centerof';
        offset?: number;
        id?: string;
    }

    export interface Location {
        x: X | number;
        y: Y | number;
    }

    export interface ElementDef {
        type: LiteralUnion<SupportedElementTypes>;
        location: Location;
        id?: string;
        width?: number;
        height?: number;
        maxWidth?: number;
        maxHeight?: number;
        url?: string;
        isDataURL?: boolean;
        scale?: number;
        text?: string;
        color?: string;
        fontSize?: number;
        fontStyle?: string;
        fontName?: string;
        imageType?: string;
        refId?: string;
    }

    export interface ProcessedElementDef extends ElementDef {
        type: SupportedElementTypes;
        size: { width: number; height: number };
        pos?: { x: number; y: number };
    }

    export interface ImageLikeElement extends ProcessedElementDef {
        imgData: string;
        imageType: string;
    }

    export interface RawHTMLElement extends ImageLikeElement {
        type: 'html';
        refId: string;
    }

    export interface ImageElement extends ImageLikeElement {
        type: 'image';
        url: string;
    }

    export interface CustomImageElement extends ImageLikeElement {
        type: 'image';
        refId: string;
    }

    export interface TextElement extends ProcessedElementDef {
        type: 'text';
        text: string;
        color: string;
        fontSize: number;
        fontStyle: string;
        fontName: string;
    }

    export interface NoneElement extends ProcessedElementDef {
        type: 'none';
    }

    export interface MapElement extends ImageLikeElement {
        type: 'map';
    }

    export type ProcessedElement =
        | TextElement
        | ImageElement
        | RawHTMLElement
        | MapElement
        | CustomImageElement
        | NoneElement;

    export interface ElementList {
        elements: ElementDef[];
    }

    export interface ProcessedElementList {
        elements: ProcessedElement[];
    }

    export interface RenderedPage extends ProcessedElementList {
        header: ProcessedElementList;
        footer: ProcessedElementList;
    }

    export interface Page extends ElementList {
        header?: ElementList;
        footer?: ElementList;
        paperSize?: SupportedPaperSize;
        paperOrientation?: 'portrait' | 'landscape';
    }

    export interface Defaults {
        fontSize: number;
        fontName: string;
        fontStyle: string;
        textColor: string;
        margin: Margin;
        paperSize: SupportedPaperSize;
        paperOrientation: 'portrait' | 'landscape';
        header: ElementList;
        footer: ElementList;
    }

    export interface Callbacks {
        addHTML?: (id: string) => HTMLElement | string | null | Promise<HTMLElement | string | null>;
        getMapId?: (id: string) => HTMLElement | null;
        getImageData?: (id: string) => string | null | Promise<string | null>;
    }

    export interface Root {
        defaults: Partial<Defaults>;
        variables?: Partial<Record<VariableKeyType, string | number | undefined | null>>;
        pages: Page[];
        callbacks?: Callbacks;
        compress?: boolean;
        name: string;
        summary?: string;
    }

    export interface ProcessedRoot {
        defaults: Defaults;
        variables: Partial<Record<VariableKeyType, string | number | undefined | null>>;
        pages: Page[];
        callbacks: Callbacks;
        compress: boolean;
        name: string;
        summary: string;
    }
}

export const supportedElementTypes = Object.keys(SupportedElementTypesEnum).filter((x) =>
    Number.isNaN(+x)
) as SupportedElementTypes[];

export interface ReportElement {
    render: (
        document: jsPDF,
        element: ReportConfig.ProcessedElement,
        page: ReportConfig.Page,
        config: ReportConfig.ProcessedRoot
    ) => Promise<void>;
    preprocess: (
        document: jsPDF,
        element: ReportConfig.ElementDef,
        page: ReportConfig.Page,
        config: ReportConfig.ProcessedRoot
    ) => Promise<ReportConfig.ProcessedElement | null>;
}

export type Elements = Record<SupportedElementTypes, ReportElement>;

export const SupportedPaperSizes = Object.keys(SupportedPaperSizesEnum).filter((x) =>
    Number.isNaN(+x)
) as SupportedPaperSize[];

export const pageSizes: Record<SupportedPaperSize, { width: number; height: number }> = {
    A4: {
        width: 210,
        height: 297,
    },
    A3: {
        width: 297,
        height: 420,
    },
};

const errorConfig: ReportConfig.Root = {
    pages: [
        {
            elements: [
                {
                    type: 'text',
                    color: '#D91E18',
                    text: 'The provided template contains an error.',
                    location: {
                        x: {
                            align: 'center',
                        },
                        y: {
                            align: 'top',
                            offset: 10,
                        },
                    },
                    fontSize: 14,
                },
            ],
        },
    ],
    name: 'errorReport',
    defaults: {
        margin: {
            bottom: 20,
            left: 20,
            right: 20,
            top: 20,
        },
    },
};

const isValidElementType = (type: string): type is SupportedElementTypes =>
    supportedElementTypes.indexOf(type as SupportedElementTypes) > -1;

const isValidElement = (element: any): element is ReportConfig.ElementDef => {
    if (!element) return false;
    if (!element.location) return false;

    if (supportedElementTypes.indexOf(element.type) === -1) return false;

    return true;
};

export const validateReportConfig = (config: ReportConfig.Root): ReportConfig.ProcessedRoot | null => {
    if (!config.name) return null;
    if (!config.pages) return null;

    if (
        !config.pages.find((page) => {
            if (!page.elements) return false;

            if (page.elements.find((element) => !isValidElement(element))) return false;

            if (page.header) {
                if (!page.header.elements) return false;

                if (page.header.elements.find((element) => !isValidElement(element))) return false;
            }

            if (page.footer) {
                if (!page.footer.elements) return false;

                if (page.footer.elements.find((element) => !isValidElement(element))) return false;
            }

            return true;
        })
    )
        return null;

    if (config.pages.length === 0) return null;

    if (!config.defaults) return null;

    if (!config.defaults.margin)
        config.defaults.margin = {
            bottom: 10,
            left: 10,
            right: 10,
            top: 10,
        };

    if (!config.defaults.header)
        config.defaults.header = {
            elements: [],
        };

    if (!config.defaults.footer)
        config.defaults.footer = {
            elements: [],
        };

    if (!config.defaults.fontName) config.defaults.fontName = 'times';
    if (!config.defaults.textColor) config.defaults.textColor = '#000';
    if (!config.defaults.fontSize) config.defaults.fontSize = 12;
    if (!config.defaults.fontStyle) config.defaults.fontStyle = 'normal';
    if (!config.defaults.paperSize) config.defaults.paperSize = 'A4';
    if (!config.defaults.paperOrientation) config.defaults.fontStyle = 'portrait';

    if (!config.callbacks) config.callbacks = {};
    if (!config.variables) config.variables = {};
    if (!config.compress) config.compress = true;

    if (!config.summary) config.summary = '';

    return config as ReportConfig.ProcessedRoot;
};

const checkImageLoadEvent = (target: any): target is HTMLImageElement => {
    if (target.naturalWidth != null && target.naturalHeight != null) return true;

    return false;
};

export class PDFReport {
    private config: ReportConfig.ProcessedRoot | null = null;

    private renderedPages: ReportConfig.RenderedPage[] = [];

    private renderedDefaultHeader: ReportConfig.ProcessedElementList = {
        elements: [],
    };

    private renderedDefaultFooter: ReportConfig.ProcessedElementList = {
        elements: [],
    };

    private isRendering: 'page' | 'header' | 'footer' | 'defaultheader' | 'defaultfooter' | null = null;

    constructor(config: string | ReportConfig.Root) {
        if (typeof config === 'string') {
            // Treat as URL extension to load and attempt to load
            fetch(config)
                .then(async (res) => validateReportConfig(await res.json()))
                .then((loadedConfig) => {
                    if (loadedConfig) {
                        this.config = loadedConfig;
                    } else {
                        this.config = validateReportConfig(errorConfig);
                    }
                })
                .catch(() => {
                    this.config = validateReportConfig(errorConfig);
                });
        } else {
            this.config = validateReportConfig(config) ?? validateReportConfig(errorConfig);
        }
    }

    private getPageSize() {
        if (!this.config) return { width: 0, height: 0 };

        const currentPage =
            this.config.variables?.page_number != null && this.config.pages[+this.config.variables.page_number - 1];

        if (currentPage) {
            const paperSize = currentPage.paperSize ?? this.config.defaults.paperSize ?? 'A4';
            const paperOrientation =
                currentPage.paperOrientation ?? this.config.defaults.paperOrientation ?? 'portrait';

            if (paperOrientation === 'portrait') {
                return pageSizes[paperSize];
            }
            return {
                width: pageSizes[paperSize].height,
                height: pageSizes[paperSize].width,
            };
        }

        return { width: 0, height: 0 };
    }

    private addImageData: ReportElement['render'] = async (doc, element, _, config) => {
        if (element.type === 'html' || element.type === 'image' || element.type === 'map' || element.type === 'none') {
            const pos = this.calculatePosition(
                element.location.x,
                element.location.y,
                element.size.width,
                element.size.height,
                config
            );

            if (pos.x != null && pos.y != null) {
                // @ts-ignore weird type check seems to think pos.x/pos.y can be null
                element.pos = pos;
                if (element.type !== 'none')
                    doc.addImage(
                        element.imgData,
                        element.imageType || 'PNG',
                        pos.x,
                        pos.y,
                        element.size.width,
                        element.size.height
                    );
            }
        }
    };

    private calculateImageSize = (
        width: number,
        height: number,
        element: ReportConfig.ElementDef
    ): { width: number; height: number } => {
        const scale = element.scale ?? 1;

        const ratio = width / height;

        // Calculate automatic size
        const size = {
            width: this.pxTomm(width * scale),
            height: this.pxTomm(height * scale),
        };

        // if width is specified, then use that but keep aspect ratio
        if (element.width) {
            size.width = element.width;
            size.height = element.width / ratio;
        }

        // if height is also specified then use that
        if (element.height) {
            size.height = element.height;
        }

        if (element.maxWidth != null && element.maxHeight != null) {
            if (element.maxWidth / size.width < element.maxHeight / size.height) {
                size.width *= element.maxWidth / size.width;
                size.height *= element.maxWidth / size.width;
            } else {
                size.width *= element.maxHeight / size.height;
                size.height *= element.maxHeight / size.height;
            }
        } else if (element.maxWidth != null && size.width > element.maxWidth) {
            size.width = element.maxWidth;
            size.height = size.width / ratio;
        } else if (element.maxHeight != null && size.height > element.maxHeight) {
            size.height = element.maxHeight;
            size.width = size.height * ratio;
        }

        return size;
    };

    /**
        Contains a mapping of element types to functions.
        Each function should accept a document, the element details 
        and optionally the full config (used for defaults)
    */
    private elements: Elements = {
        none: {
            preprocess: async (doc, element) => {
                if (element.location == null) return null;
                if (!this.config) return null;

                const noneElement: ReportConfig.NoneElement = {
                    ...element,
                    type: 'none',
                    size: {
                        width: 0,
                        height: 0,
                    },
                };

                return noneElement;
            },
            render: this.addImageData,
        },
        text: {
            preprocess: async (doc, element, page, config) => {
                if (element.location == null) return null;
                if (element.text == null) return null;
                if (!this.config) return null;

                const fontName = element.fontName || config.defaults.fontName;
                const fontSize = element.fontSize || config.defaults.fontSize;
                const fontStyle = element.fontStyle || config.defaults.fontStyle;
                const color = element.color || config.defaults.textColor;

                const text =
                    config.variables != null
                        ? await this.applyVariables(element.text, this.config.variables)
                        : element.text;

                const dims = doc.getTextDimensions(text, {
                    fontSize,
                    maxWidth: element.width,
                    scaleFactor: element.scale,
                });

                const textElement: ReportConfig.TextElement = {
                    ...element,
                    type: 'text',
                    size: {
                        width: dims.w,
                        height: dims.h,
                    },
                    color,
                    fontName,
                    fontStyle,
                    fontSize,
                    text,
                };

                return textElement;
            },
            render: async (doc, element, page, config) => {
                if (element.type === 'text') {
                    doc.setFont(element.fontName, element.fontStyle);
                    doc.setFontSize(element.fontSize);
                    doc.setTextColor(element.color);

                    const pos = this.calculatePosition(
                        element.location.x,
                        element.location.y,
                        element.size.width,
                        element.size.height,
                        config
                    );

                    if (pos.x != null && pos.y != null) {
                        // @ts-ignore weird type check seems to think pos.x/pos.y can be null
                        element.pos = pos;
                        doc.text(element.text, pos.x, pos.y, {
                            maxWidth: element.width,
                        });
                    }
                }
            },
        },
        html: {
            preprocess: async (doc, element, page, config) => {
                if (config.callbacks?.addHTML == null) return null;
                if (element.refId == null) return null;
                if (element.location == null) return null;

                let html = await config.callbacks.addHTML(element.refId);

                if (html == null) return null;

                let needsToUnload = false;

                const hiddenDiv = document.createElement('div');

                if (typeof html === 'string') {
                    const div = document.createElement('div');
                    needsToUnload = true;

                    hiddenDiv.setAttribute('style', 'width:1;height:1;overflow: hidden; position: absolute;');

                    document.body.append(hiddenDiv);
                    hiddenDiv.append(div);

                    div.innerHTML = html;
                    html = div;
                }

                const canvas = await html2canvas(html, {
                    scale: element.scale ?? 1,
                    imageTimeout: 0,
                    useCORS: true,
                    allowTaint: true,
                });

                const htmlElement: ReportConfig.RawHTMLElement = {
                    ...element,
                    type: 'html',
                    refId: element.refId,
                    size: this.calculateImageSize(canvas.width, canvas.height, element),
                    imgData: canvas.toDataURL('image/png', 1.0),
                    imageType: 'PNG',
                };

                if (needsToUnload) {
                    document.body.removeChild(hiddenDiv);
                }

                return htmlElement;
            },
            render: this.addImageData,
        },
        image: {
            preprocess: async (doc, element) => {
                if (element.location == null) return null;
                if (element.url == null) return null;

                let { url } = element;
                if (!element.url.startsWith('https')) {
                    url = window.location.origin + element.url;
                }

                const img = await this.loadImage(url, element.imageType ?? 'png');

                const imageElement: ReportConfig.ImageElement = {
                    ...element,
                    type: 'image',
                    size: this.calculateImageSize(img.width, img.height, element),
                    imgData: img.dataURL,
                    url: element.url,
                    imageType: element.imageType?.toUpperCase() ?? 'PNG',
                };

                return imageElement;
            },
            render: this.addImageData,
        },
        custom_image: {
            preprocess: async (doc, element, page, config) => {
                if (config.callbacks?.getImageData == null) return null;
                if (element.location == null) return null;
                if (element.refId == null) return null;

                const dataUrl = await config.callbacks.getImageData(element.refId);

                if (!dataUrl)
                    return {
                        ...element,
                        type: 'none',
                        size: {
                            height: 0,
                            width: 0,
                        },
                    };

                const originalSize = await new Promise<{ width: number; height: number }>((resolve) => {
                    const i = new Image();
                    i.onload = () => {
                        resolve({ width: i.naturalWidth, height: i.naturalHeight });
                    };
                    i.src = dataUrl;
                });

                const imageElement: ReportConfig.CustomImageElement = {
                    ...element,
                    type: 'image',
                    size: this.calculateImageSize(originalSize.width, originalSize.height, element),
                    imgData: dataUrl,
                    refId: element.refId,
                    imageType: element.imageType?.toUpperCase() ?? 'PNG',
                };

                return imageElement;
            },
            render: this.addImageData,
        },
        map: {
            preprocess: async (doc, element, page, config) => {
                if (config.callbacks?.getMapId == null) return null;
                if (element.location == null) return null;

                const map = config.callbacks.getMapId(element.id ?? '');

                if (map == null) return null;

                const canvas = await html2canvas(map, {
                    useCORS: true,
                    imageTimeout: 0,
                    allowTaint: true,
                });

                const mapElement: ReportConfig.MapElement = {
                    ...element,
                    type: 'map',
                    size: this.calculateImageSize(canvas.width, canvas.height, element),
                    imgData: canvas.toDataURL('image/png', 1.0),
                    imageType: 'PNG',
                };

                return mapElement;
            },
            render: async (doc, element, page, config) => {
                if (element.type === 'map') {
                    const pos = this.calculatePosition(
                        element.location.x,
                        element.location.y,
                        element.size.width,
                        element.size.height,
                        config
                    );

                    if (pos.x != null && pos.y != null) {
                        // @ts-ignore weird type check seems to think pos.x/pos.y can be null
                        element.pos = pos;
                        doc.addImage(
                            element.imgData,
                            element.imageType || 'PNG',
                            pos.x,
                            pos.y,
                            element.size.width,
                            element.size.height
                        );
                    }

                    // Draw Border of Map
                    doc.lines(
                        [
                            [0, 0],
                            [element.size.width, 0],
                            [0, element.size.height],
                            [-element.size.width, 0],
                            [0, -element.size.height],
                        ],
                        pos.x,
                        pos.y
                    );
                }
            },
        },
    };

    setCallback = <T extends keyof ReportConfig.Callbacks>(id: T, cb: ReportConfig.Callbacks[T]) => {
        if (this.config) this.config.callbacks[id] = cb;
    };

    setVariable = (key: string, value?: string | number | null) => {
        if (this.config) this.config.variables[key] = value;
    };

    generateReport = async () => {
        if (this.config == null) {
            console.error('Report not initialised');
            return null;
        }

        const doc = new jsPDF({
            unit: 'mm',
            compress: this.config.compress,
            format: this.config.pages[0].paperSize ?? this.config.defaults.paperSize,
            orientation: this.config.pages[0].paperOrientation ?? this.config.defaults.paperOrientation,
        });

        let firstPage = true;

        // Should be DateTimeFormatOptions
        const dateOptions: any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        this.config.variables = {
            date: new Date()
                .toLocaleDateString('en-AU', {
                    weekday: 'short',
                    day: '2-digit',
                    month: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    year: 'numeric',
                    // @ts-ignore
                    hourCycle: 'h23',
                })
                .replace(/,/g, ''),
            long_date: new Date().toLocaleDateString('en-AU', dateOptions),
            ...this.config.variables,
            total_pages: this.config.pages.length,
        };

        for (let index = 0; index < this.config.pages.length; index += 1) {
            if (this.config) {
                this.config.variables.page_number = index + 1;

                await this.generatePage(doc, this.config.pages[index], !firstPage);
                firstPage = false;
            }
        }

        return doc;
    };

    private async generatePage(doc: jsPDF, page: ReportConfig.Page, addPage = true) {
        if (this.config) {
            if (addPage) {
                doc.addPage(
                    page.paperSize ?? this.config.defaults.paperSize,
                    page.paperOrientation ?? this.config.defaults.paperOrientation
                );
            }

            const pageNum = (this.config.variables.page_number as number) - 1;

            if (!this.renderedPages[pageNum]) {
                this.renderedPages[pageNum] = {
                    elements: [],
                    header: {
                        elements: [],
                    },
                    footer: {
                        elements: [],
                    },
                };
            }

            await this.applyHeaderFooter(doc, page);

            this.isRendering = 'page';
            await this.applyElements(doc, page.elements, page, this.renderedPages[pageNum].elements);
        }
    }

    private async applyHeaderFooter(doc: jsPDF, page: ReportConfig.Page) {
        if (this.config) {
            const pageNum = (this.config.variables.page_number as number) - 1;

            if (page.header) {
                this.isRendering = 'header';
                await this.applyElements(doc, page.header.elements, page, this.renderedPages[pageNum].header?.elements);
            } else {
                this.isRendering = 'defaultheader';
                await this.applyElements(
                    doc,
                    this.config.defaults.header.elements,
                    page,
                    this.renderedDefaultHeader.elements
                );
            }

            if (page.footer) {
                this.isRendering = 'footer';
                await this.applyElements(doc, page.footer.elements, page, this.renderedPages[pageNum].footer?.elements);
            } else {
                this.isRendering = 'defaultfooter';
                await this.applyElements(
                    doc,
                    this.config.defaults.footer.elements,
                    page,
                    this.renderedDefaultFooter.elements
                );
            }
        }
    }

    private async applyElements(
        doc: jsPDF,
        elements: ReportConfig.ElementDef[],
        page: ReportConfig.Page,
        resultsRef: ReportConfig.ProcessedElement[]
    ) {
        if (elements) {
            // Preprocess elements
            for (let index = 0; index < elements.length; index += 1) {
                const element = elements[index];
                if (isValidElementType(element.type) && this.config) {
                    const processedElement = await this.elements[element.type].preprocess(
                        doc,
                        element,
                        page,
                        this.config
                    );
                    if (processedElement) {
                        resultsRef[index] = processedElement;
                    }
                }
            }

            // Render elements
            const promises: Promise<void>[] = [];
            elements.forEach((element, index) => {
                if (isValidElementType(element.type) && this.config) {
                    if (resultsRef[index]) {
                        promises.push(this.elements[element.type].render(doc, resultsRef[index], page, this.config));
                    }
                }
            });

            await Promise.all(promises);
        }
    }

    private applyVariables = async (
        text: string,
        variables: Partial<Record<VariableKeyType, string | number | undefined | null>>
    ): Promise<string> => {
        let result = text;

        Object.keys(variables).forEach((name: keyof typeof variables) => {
            const val = variables[name];
            if (val !== null) result = result.replace(`\${${name}}`, val != null ? `${val}` : 'null');
        });
        return result;
    };

    // eslint-disable-next-line class-methods-use-this
    private async loadImage(url: string, type = 'png'): Promise<{ width: number; height: number; dataURL: string }> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.setAttribute('crossOrigin', 'anonymous');

            // eslint-disable-next-line func-names
            image.onload = (event) => {
                if (checkImageLoadEvent(event.currentTarget)) {
                    const canvas = document.createElement('canvas');
                    canvas.width = event.currentTarget.naturalWidth;
                    canvas.height = event.currentTarget.naturalHeight;
                    const ctx = canvas.getContext('2d');

                    if (ctx) {
                        ctx.drawImage(event.currentTarget, 0, 0);

                        resolve({
                            width: canvas.width,
                            height: canvas.height,
                            dataURL: canvas.toDataURL(`image/${type}`, 1.0),
                        });
                    }
                    reject(new Error('Unable to get canvas 2d context'));
                }
                reject(new Error('Image onload event invalid'));
            };

            image.src = url;
        });
    }

    private calculatePosition(
        _x: ReportConfig.X | number,
        _y: ReportConfig.Y | number,
        width: number,
        height: number,
        config: ReportConfig.ProcessedRoot
    ) {
        let x: number | null = null;
        let y: number | null = null;
        const pageSize = this.getPageSize();
        let listOfElementsBeingRendered: ReportConfig.ProcessedElement[] = [];
        switch (this.isRendering) {
            case 'page':
                if (config.variables?.page_number != null) {
                    listOfElementsBeingRendered = this.renderedPages[+config.variables.page_number - 1].elements;
                }
                break;
            case 'header':
                if (config.variables?.page_number != null) {
                    listOfElementsBeingRendered =
                        this.renderedPages[+config.variables.page_number - 1].header?.elements ?? [];
                }
                break;
            case 'footer':
                if (config.variables?.page_number != null) {
                    listOfElementsBeingRendered =
                        this.renderedPages[+config.variables.page_number - 1].footer?.elements ?? [];
                }
                break;
            case 'defaultheader':
                listOfElementsBeingRendered = this.renderedDefaultHeader.elements;
                break;
            case 'defaultfooter':
                listOfElementsBeingRendered = this.renderedDefaultFooter.elements;
                break;
            default:
                break;
        }

        if (typeof _x === 'object') {
            if (_x.align === 'left') {
                x = config.defaults.margin.left;
            } else if (_x.align === 'center') {
                x = pageSize.width / 2 - width / 2;
            } else if (_x.align === 'right') {
                x = pageSize.width - config.defaults.margin.right - width;
            } else if (_x.id && config.variables?.page_number != null) {
                const element = this.getElementById(_x.id, listOfElementsBeingRendered);
                if (element?.pos) {
                    if (_x.align === 'rightof') {
                        x = element.pos.x + element.size.width + 1;
                    } else if (_x.align === 'leftof') {
                        x = element.pos.x - width - 1;
                    } else if (_x.align === 'centerof') {
                        x = element.pos.x + element.size.width / 2 - width / 2;
                    }
                } else {
                    console.error(`Report Generator: Unable to find reference to element with id ${_x.id}`);
                }
            }
            if (_x.offset && x != null) {
                x += _x.offset;
            }
        } else {
            x = _x;
        }

        if (typeof _y === 'object') {
            if (_y.align === 'top') {
                y = config.defaults.margin.top;
            } else if (_y.align === 'center') {
                y = pageSize.height / 2 - height / 2;
            } else if (_y.align === 'bottom') {
                y = pageSize.height - config.defaults.margin.bottom - height;
            } else if (_y.id && config.variables?.page_number != null) {
                const element = this.getElementById(_y.id, listOfElementsBeingRendered);
                if (element?.pos) {
                    if (_y.align === 'under') {
                        y = element.pos.y + element.size.height + 1;
                    } else if (_y.align === 'over') {
                        y = element.pos.y - height - 1;
                    } else if (_y.align === 'centerof') {
                        y = element.pos.y + element.size.height / 2 - height / 2;
                    }
                } else {
                    console.error(`Report Generator: Unable to find reference to element with id ${_y.id}`);
                }
            }
            if (_y.offset && y != null) {
                y += _y.offset;
            }
        } else {
            y = _y;
        }
        return { x, y };
    }

    private getElementById = <T extends { id?: string }>(id: string, elements: T[]) =>
        elements.find((e) => e?.id === id);

    private pxTomm = (px: number) => (px * 25.4) / (96 * window.devicePixelRatio);

    private mmTopx = (mm: number) => mm / (this.pxTomm(100) / 100);
}
