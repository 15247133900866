import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { QueryManager } from 'models';

export interface QueryState {
    fields: Partial<Record<QueryManager.FieldIds, QueryManager.Field>> | null;
    fieldsState: 'init' | 'loading' | 'finished' | 'error';
}

const initialState: QueryState = {
    fields: null,
    fieldsState: 'init',
};

export const getFields = createAsyncThunk('query/getFields', async () => QueryManager.getFields(), {
    condition: (_, { getState }) => {
        const { fields } = getState() as RootState;
        if (fields.fieldsState === 'loading' || fields.fieldsState === 'finished') {
            // Already fetched or in progress, don't need to re-fetch
            return false;
        }
        return true;
    },
});

export const fieldsSlice = createSlice({
    name: 'fields',
    initialState,
    reducers: {
        setFields: (state, action: PayloadAction<QueryState['fields']>) => {
            state.fields = action.payload;
        },
        setFieldsState: (state, action: PayloadAction<QueryState['fieldsState']>) => {
            state.fieldsState = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFields.pending, (state) => {
            state.fieldsState = 'loading';
        });

        builder.addCase(getFields.rejected, (state) => {
            state.fieldsState = 'error';
        });

        builder.addCase(getFields.fulfilled, (state, action) => {
            state.fieldsState = 'finished';
            state.fields = action.payload;
        });
    },
});

export const fieldActions = {
    getFields,
    ...fieldsSlice.actions,
};

export const selectQuery = (state: RootState) => state.fields;

export default fieldsSlice.reducer;
