/**
 * The business root of the application
 */

import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

import Error404Page from 'pages/Error404';
import JobSummaryPage from 'pages/JobSummary';
import JobNewSelectPage from 'pages/JobNewSelect';
import JobDetails from 'pages/JobDetails';
import JobResults from 'pages/JobResults';
import JobNewProgress from 'pages/JobNewProgress';
import JobNewDetails from 'pages/JobNewDetails';
import JobNewFeatures from 'pages/JobNewFeatures';
import JobNewModificationSummary from 'pages/JobNewModificationSummary';
import JobNewModificationAdd from 'pages/JobNewModificationAdd';

import { useAppSelector, useAppDispatch } from 'hooks';
import { fieldActions } from 'slices/fields';
import { jobActions } from 'slices/job';
import { CalculatorManager, JobManager, QueryManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            backgroundColor: theme.palette.grey[200],
            height: '100%',
        },
        leftPage: {
            backgroundColor: theme.palette.grey[100],
            overflow: 'hidden',
        },
        rightPage: {
            backgroundColor: theme.palette.grey[200],
            overflow: 'hidden',
        },
        testPageLoaded: {
            display: 'none',
        },
    })
);

function App() {
    const classes = useStyles();

    const history = useHistory();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const { fields } = useAppSelector((state) => state);

    const [newJob, setNewJob] = useState<JobManager.NewJob>({});

    const [completedSteps, setCompletedSteps] = useState<Record<string, boolean>>({});

    const cancelNewJob = () => {
        setNewJob({});
        setCompletedSteps({});
        dispatch(jobActions.getJobs());
        history.push('/new');
    };

    const onSelectCalculator = (calculator: CalculatorManager.Calculator) => {
        setNewJob({ query: calculator.defaultQuery, ...newJob, type: calculator.id });

        history.push(`/new/${calculator.id}/details`);
    };

    const onDetailsUpdate = (name: string, description: string) => {
        if (name) {
            setCompletedSteps({ ...completedSteps, details: true });
            setNewJob({ ...newJob, name, description });
        } else {
            setCompletedSteps({ ...completedSteps, details: false });
        }
    };

    const onDetailsNext = () => {
        history.push(`/new/${newJob.type}/features`);
    };

    const onFeaturesUpdate = (query: Partial<QueryManager.Query>) => {
        setCompletedSteps({ ...completedSteps, features: true });

        const { fieldValues } = query;

        const where = fieldValues ? QueryManager.fieldValuesToWhere(fieldValues, fields.fields) : '';

        setNewJob({ ...newJob, query: { ...newJob.query, ...query, where } });
    };

    const onFeaturesNext = () => {
        history.push(`/new/${newJob.type}/modifications`);
    };

    const addNewModification = (mod: JobManager.Modification) => {
        setNewJob({ ...newJob, modifications: [...(newJob.modifications ?? []), mod] });
        setCompletedSteps({ ...completedSteps, modifications: true });
    };

    const removeModification = (index: number) => {
        setNewJob({ ...newJob, modifications: (newJob.modifications ?? []).filter((_, idx) => idx !== index) });
    };

    const goToAddMofificationPage = () => {
        history.push(`/new/${newJob.type}/modifications/add`);
    };

    const submitJob = async () => {
        const job = await JobManager.addJob(newJob);
        if (job) {
            setNewJob({});
            setCompletedSteps({});
            dispatch(jobActions.getJobs());
        }
        return job;
    };

    useEffect(() => {
        if (fields.fieldsState === 'init') dispatch(fieldActions.getFields());
    }, [fields.fieldsState]);

    const loadNewJobs = (params: Record<string, string>) => {
        const jobParams: JobManager.NewJob = { ...newJob };

        const { type } = params;

        if (type) jobParams.type = type as CalculatorManager.CalculatorId;

        const { title, description, geometry, geometryType, spatialRel } = params;

        if (title) jobParams.name = title;

        if (description) jobParams.description = description;

        if (!jobParams.query && (params.fieldValues || geometry)) {
            jobParams.query = { where: '', fieldValues: {} };

            if (geometry) jobParams.query.geometry = geometry;

            if (geometry && geometryType)
                jobParams.query.geometryType = geometryType as QueryManager.Query['geometryType'];

            if (geometry && spatialRel) jobParams.query.spatialRel = spatialRel;

            const fieldValuesStr = params.fieldValues;
            if (fieldValuesStr) {
                try {
                    const fieldValues = (JSON.parse(fieldValuesStr) as QueryManager.Query['fieldValues']) ?? {};

                    jobParams.query.fieldValues = fieldValues;
                    jobParams.query.where = QueryManager.fieldValuesToWhere(fieldValues, fields.fields);
                } catch (err) {
                    console.error('Unable to parse the fieldValues object. Will default to no filters.');
                }
            }
        }

        if (type) history.push(`/new/${type}/details`);

        setNewJob(jobParams);
    };

    const onUploadJob = (job: JobManager.NewJob) => {
        const { query, name, description, modifications, type } = job;
        setNewJob({ ...newJob, query, name, description, modifications, type });

        if (type) history.push(`/new/${type}/details`);
    };

    useEffect(() => {
        if (fields.fieldsState === 'finished') {
            // If the application is first loaded with some new job settings
            const searchParams = new URLSearchParams(location.search);

            loadNewJobs(Object.fromEntries(searchParams));
        }
    }, [fields.fieldsState]);

    return (
        <div className={classes.root}>
            <div className={classes.leftPage}>
                <Switch>
                    <Route exact path={['/', '/new']} render={(props) => <JobSummaryPage {...props} />} />
                    <Route
                        path={['/new/:calc', '/new']}
                        render={(props) => (
                            <JobNewProgress {...props} completed={completedSteps} onCancelNewJob={cancelNewJob} />
                        )}
                    />
                    <Route
                        exact
                        path={['/job/:id', '/job/:id/details', '/job/:id/results']}
                        render={(props) => <JobSummaryPage {...props} />}
                    />
                    <Route path="*" component={Error404Page} />
                </Switch>
            </div>
            <div className={classes.rightPage}>
                <Switch>
                    <Route
                        exact
                        path={['/', '/new']}
                        render={(props) => (
                            <JobNewSelectPage
                                {...props}
                                onUploadJob={onUploadJob}
                                onSelectCalculator={onSelectCalculator}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={['/new/:calc/details', '/new/:calc']}
                        render={(props) => (
                            <JobNewDetails
                                {...props}
                                onSectionUpdate={onDetailsUpdate}
                                job={newJob}
                                onNextSection={onDetailsNext}
                            />
                        )}
                    />
                    <Route
                        path="/new/:calc/features"
                        render={(props) => (
                            <JobNewFeatures
                                {...props}
                                onSectionUpdate={onFeaturesUpdate}
                                job={newJob}
                                onNextSection={onFeaturesNext}
                            />
                        )}
                    />
                    <Route
                        path="/new/:calc/modifications/add"
                        render={(props) => (
                            <JobNewModificationAdd {...props} job={newJob} onAddModification={addNewModification} />
                        )}
                    />
                    <Route
                        path="/new/:calc/modifications"
                        render={(props) => (
                            <JobNewModificationSummary
                                {...props}
                                job={newJob}
                                onAddNewModification={goToAddMofificationPage}
                                onRemoveModification={removeModification}
                                onSubmitJob={submitJob}
                            />
                        )}
                    />
                    <Route exact path={['/job/:id', '/job/:id/details']} component={JobDetails} />
                    <Route exact path="/job/:id/results" component={JobResults} />
                    <Route path="*" component={Error404Page} />
                </Switch>
            </div>
            <div className={classes.testPageLoaded}>PageLoaded</div>
        </div>
    );
}

export default App;
