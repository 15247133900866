import { createTheme as createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Palette } from '@material-ui/core/styles/createPalette';

import { Status } from 'models/job';

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        status: Record<Status, React.CSSProperties['color']>;
    }
    // allow configuration using `createTheme`
    interface PaletteOptions {
        status?: Partial<Record<Status, React.CSSProperties['color']>>;
    }
}

export default function createTheme(options: ThemeOptions) {
    return createMuiTheme({
        palette: {
            status: {
                Ready: '#4caf50',
                Pending: '#ff9800',
                Processing: '#2196f3',
                Error: '#f44336',
            },
            ...options.palette,
        },
        ...options,
    });
}
