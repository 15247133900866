import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

import { JobManager, QueryManager } from 'models';

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        root: {
            margin: `${0}px ${spacing(1)}px`,
            marginBottom: spacing(1),
            padding: spacing(0.5),
            backgroundColor: grey[100],
            borderRadius: 4,
        },
        pdfRoot: {
            margin: `${0}px ${spacing(1)}px`,
            marginBottom: spacing(1),
            padding: spacing(0.5),
            borderRadius: 4,
        },
        descriptionText: {
            paddingLeft: spacing(0.5),
        },
        row: {
            display: 'grid',
            gridTemplateColumns: '1fr auto',
        },
        closeButton: {
            cursor: 'pointer',
        },
    })
);

interface Props {
    set: JobManager.Modification['set'];
    onRemoveSet?: (id: QueryManager.FieldIds, index: number) => void;
    fields: Partial<Record<QueryManager.FieldIds, QueryManager.Field>> | null;
    isPDF?: boolean;
}

const ListSetChanges: React.FunctionComponent<Props> = ({ set, onRemoveSet, fields, isPDF }) => {
    const classes = useStyles();

    return (
        <div className={isPDF ? classes.pdfRoot : classes.root}>
            {Object.keys(set).map((fieldId) => {
                const update = set[fieldId as QueryManager.FieldIds];
                const field = fields?.[fieldId as QueryManager.FieldIds];

                if (!update || !field) return <></>;

                return update.map((x: any, index) => {
                    const indexOfTo = field.values?.indexOf(x[2]);

                    return (
                        <div key={`${fieldId}${x[1]}${x[2]}`} className={classes.row}>
                            <Typography variant="subtitle2" className={classes.descriptionText}>
                                {/* @ts-ignore */}
                                {field.name}:{' '}
                                {QueryManager.fieldValuesToPrettyWhere(x[0], fields).replaceAll(field.name, '')}
                                {/* @ts-ignore */}
                                {field.units} {'\u2192'} {field.labels?.[indexOfTo] ?? x[2]}
                                {field.units}
                            </Typography>
                            {onRemoveSet ? (
                                <Close className={classes.closeButton} onClick={() => onRemoveSet(field.id, index)} />
                            ) : (
                                <div />
                            )}
                        </div>
                    );
                });
            })}
        </div>
    );
};

export default ListSetChanges;
