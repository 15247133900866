import { FieldsEnum, unprocessedFields } from 'config/fields';
import { MAPSERVER_LAYER_URL } from 'config';

import { getToken } from 'models/auth';
import { makeIdPretty } from 'utils';

import enums from 'config/enums.json';
import domains from 'config/domains.json';

export type FieldIds = keyof typeof FieldsEnum;

export const fieldIds = Object.keys(FieldsEnum).filter((x) => Number.isNaN(+x)) as FieldIds[];

export const TOKENS = {
    OR: ' OR ',
    AND: ' AND ',
    EQUALS: ' = ',
};

export interface Query {
    geometry?: string;
    geometryType?:
        | 'esriGeometryPoint'
        | 'esriGeometryMultipoint'
        | 'esriGeometryPolyline'
        | 'esriGeometryPolygon'
        | 'esriGeometryEnvelope';
    spatialRel?: string;
    fieldValues?: Partial<Record<FieldIds, string>>;
    where: string;
}

export declare module ArcGIS {
    interface Error {
        code: number;
        message: string;
        details: string[];
    }

    interface Domain {
        type: 'codedValue';
        name: string;
        description: string;
        codedValues: { name: string; code: number }[];
        mergePolicy: 'esriMPTDefaultValue';
        splitPolicy: 'esriSPTDefaultValue';
    }

    interface Field {
        name: FieldIds;
        type: 'esriFieldTypeString' | 'esriFieldTypeSmallInteger' | 'esriFieldTypeInteger' | 'esriFieldTypeDouble';
        alias: string;
        length: number;
        domain: null | Domain;
    }

    interface LayerDetails {
        // There are more fields, but this is all I care about
        fields: Field[];
        error?: Error;
    }
}

export interface Field {
    id: FieldIds;
    name: string;
    units?: string;
    isNumeric?: boolean;
    values?: string[];
    labels?: string[];
    min?: number;
    max?: number;
    input?: 'typeahead' | 'text' | 'exact-text' | 'upper-text' | 'numeric' | 'multiselect' | 'select';
}

export type UnprocessedField = Omit<Partial<Field>, 'id'>;

export type Fields = Partial<Record<FieldIds, Field>>;

const esriDomainToValueLabels = (domain: ArcGIS.Domain | null): Record<string, string> | undefined =>
    domain?.codedValues?.reduce((acc, codedValue) => ({ ...acc, [`${codedValue.code}`]: codedValue.name }), {});

export const getFields = async (): Promise<Partial<Record<FieldIds, Field>>> => {
    const token = getToken();
    if (!token) return {};

    const response = await fetch(`${MAPSERVER_LAYER_URL}?f=json&token=${token}`);

    if (!response.ok) return {};

    const results = (await response.json()) as ArcGIS.LayerDetails;

    if (results.error) return {};

    const fieldList = results.fields.map<Field>(({ name: id, type, domain }) => {
        const unprocessedField = unprocessedFields[id];
        const isNumeric = type !== 'esriFieldTypeString';

        let input: Field['input'] = isNumeric ? 'numeric' : 'text';

        if (domain != null) input = 'typeahead';

        const valueSet: string[] | undefined = (domains as any)[id.toUpperCase()] ?? unprocessedField?.values;

        const valueLabels: Record<string, string> | undefined =
            esriDomainToValueLabels(domain) ?? (enums as any)[id.toUpperCase()] ?? undefined;

        const values: string[] = [];
        const labels: string[] = [];
        if (valueLabels) {
            Object.keys(valueLabels).forEach((v) => {
                values.push(v);
                labels.push(valueLabels[v]);
            });
        } else if (valueSet) {
            valueSet.forEach((v) => {
                values.push(v);
                labels.push(v);
            });
        }

        return {
            id,
            name: unprocessedField?.name ?? makeIdPretty(id),
            input: unprocessedField?.input ?? input,
            units: unprocessedField?.units,
            isNumeric,
            labels: unprocessedField?.labels ?? labels,
            values: unprocessedField?.values ?? values,
            min: unprocessedField?.min,
            max: unprocessedField?.max,
        };
    });

    return fieldList.reduce((acc, field) => ({ ...acc, [field.id]: field }), {});
};

const parseNumericalQuery = (val: string, { id }: Field) => {
    if (!Number.isNaN(Number.parseFloat(val))) {
        return `${id} = ${val}`;
    }

    if (val.replace(/null/g, '').match(/[^$.=<>!|&() \d]/g)) {
        throw new Error("Invalid Value: Must not have non-numeric characters (except 'null')");
    }

    // remove spaces and split by ;
    let where = val.replace(/ /g, '');
    where = where.replace(/&/g, ' AND ').replace(/\|/g, ' OR ');
    // each part should contain only [!,=,<,<=,>,>=] and a number or 'null' or '!null'
    where = where.replace(/!null/g, `${id} IS NOT NULL `).replace(/null/g, `${id} IS NULL `);
    where = where.replace(/!/g, `${id} nteql `);
    where = where.replace(/<=/g, `${id} lteql `);
    where = where.replace(/>=/g, `${id} gteql `);
    where = where.replace(/=/g, `${id} = `);
    where = where.replace(/</g, `${id} < `);
    where = where.replace(/>/g, `${id} > `);
    where = where.replace(/lteql/g, '<=').replace(/gteql/, '>=');
    where = where.replace(/nteql/g, '<>');
    where = where.replace(/[0-9]+-[0-9]+/g, (x) => {
        const y = x.split('-');
        return `${id} >= ${y[0]} AND ${id} < ${y[1]}`;
    });

    // remove any double spaces
    where = where.replace(/ {2}/g, ' ');

    return `(${where})`;
};

const parseNumericalQueryPretty = (val: string, { name: originalName, id }: Field) => {
    const name = originalName ?? id;
    if (!Number.isNaN(Number.parseFloat(val))) {
        return `${name} = ${val}`;
    }

    if (val.replace(/null/g, '').match(/[^$.=<>!|&() \d]/g)) {
        throw new Error("Invalid Value: Must not have non-numeric characters (except 'null')");
    }

    // remove spaces and split by ;
    let where = val.replace(/ /g, '');
    where = where.replace(/&/g, ' AND ').replace(/\|/g, ' OR ');
    // each part should contain only [!,=,<,<=,>,>=] and a number or 'null' or '!null'
    where = where.replace(/!null/g, `${name} IS NOT NULL `).replace(/null/g, `${name} IS NULL `);
    where = where.replace(/!/g, `${name} nteql `);
    where = where.replace(/<=/g, `${name} lteql `);
    where = where.replace(/>=/g, `${name} gteql `);
    where = where.replace(/=/g, `${name} = `);
    where = where.replace(/</g, `${name} < `);
    where = where.replace(/>/g, `${name} > `);
    where = where.replace(/lteql/g, '<=').replace(/gteql/, '>=');
    where = where.replace(/nteql/g, '<>');
    where = where.replace(/[0-9]+-[0-9]+/g, (x) => {
        const y = x.split('-');
        return `${name} >= ${y[0]} AND ${name} < ${y[1]}`;
    });

    // remove any double spaces
    where = where.replace(/ {2}/g, ' ');

    return where;
};

const parseStandardOptions = (where: string, { id, isNumeric }: Field) =>
    `(${where
        .split(TOKENS.OR)
        .map((value) => `${id}${TOKENS.EQUALS}${isNumeric ? value : `'${value}'`}`)
        .join(TOKENS.OR)})`;

const parseStandardOptionsPretty = (where: string, field: Field) =>
    where
        .split(TOKENS.OR)
        .map((value) => `${field.name}${TOKENS.EQUALS}${field.labels?.[field.values?.indexOf(value) ?? -1] ?? value}`)
        .join(TOKENS.OR);

const parseText = (where: string, { id, isNumeric }: Field) =>
    isNumeric ? `(${id} = ${where})` : `(Upper(${id}) = '${where.toUpperCase()}')`;

const parseTextPretty = (where: string, { name, isNumeric }: Field) =>
    isNumeric ? `${name} = ${where}` : `${name} = '${where}'`;

export const fieldValuesToWhere = (fieldValues: Partial<Record<FieldIds, string>>, fields: Fields | null) => {
    const listOfParams = Object.keys(fieldValues ?? {}).map((id) => ({
        where: `${fieldValues[id as FieldIds]}`,
        id: id as FieldIds,
    }));

    return listOfParams
        .map(({ where, id }) => {
            const field = fields?.[id];

            if (!field || !where) return null;

            if (field.input === 'typeahead' || field.input === 'multiselect') return parseStandardOptions(where, field);

            if (field.input === 'numeric') return parseNumericalQuery(where, field);

            if (field.input === 'text' || field.input === 'exact-text') return parseText(where, field);

            return null;
        })
        .filter((x) => !!x)
        .join(TOKENS.AND);
};

export const fieldValuesToPrettyWhere = (
    fieldValues: Partial<Record<FieldIds, string>>,
    fields: Fields | null,
    emptyText?: string
) => {
    const listOfParams = Object.keys(fieldValues ?? {}).map((id) => ({
        where: `${fieldValues[id as FieldIds]}`,
        id: id as FieldIds,
    }));

    if (listOfParams.length === 0) return emptyText ?? 'No attribute filters applied. All features will be selected.';

    return listOfParams
        .map(({ where, id }) => {
            const field = fields?.[id];

            if (!field || !where) return null;

            if (field.input === 'typeahead' || field.input === 'multiselect')
                return parseStandardOptionsPretty(where, field);

            if (field.input === 'numeric') return parseNumericalQueryPretty(where, field);

            if (field.input === 'text' || field.input === 'exact-text') return parseTextPretty(where, field);

            return null;
        })
        .filter((x) => !!x)
        .join(TOKENS.AND);
};
