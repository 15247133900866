import React from 'react';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import { Add, KeyboardArrowRight } from '@material-ui/icons';

import ListItem from 'components/ListItem';

import { CalculatorManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            fontSize: 32,
        },
        disabled: {
            backgroundColor: theme.palette.grey[200],
        },
    })
);

interface Props {
    calculator: CalculatorManager.Calculator;
    onClick?: () => void;
}

const CalculatorListItem: React.FunctionComponent<Props> = ({ calculator, onClick }) => {
    const classes = useStyles();

    return (
        <ListItem
            className={calculator.disabled ? classes.disabled : ''}
            primaryText={calculator.name}
            secondaryText={calculator.description}
            onClick={() => !calculator.disabled && onClick && onClick()}
            statusIcon={<Add className={classes.icon} />}
            actionIcon={<KeyboardArrowRight className={classes.icon} />}
        />
    );
};

export default CalculatorListItem;
