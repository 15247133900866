import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, TextField, Select, MenuItem, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { QueryManager } from 'models';

interface Props {
    field?: QueryManager.Field;
    value?: string;
    values?: string[];
    onChange?: (value?: string, seperateValues?: string[]) => void;
    style?: React.CSSProperties;
    className?: string;
    allowMultiple?: boolean;
    title?: string;
}

interface LabelValue {
    value: string;
    label: string;
}

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        root: {
            padding: spacing(0),
        },
        fullWidth: {
            width: '100%',
        },
    })
);

const valueListToString = (values?: string[]) => values?.join(' OR ');
const stringToValueList = (value?: string | string[]) => (Array.isArray(value) ? value : value?.split(' OR '));

const FieldInput: React.FunctionComponent<Props> = ({
    field,
    value: providedValue,
    values,
    onChange,
    style,
    className,
    allowMultiple,
    title,
}) => {
    const classes = useStyles();

    // May seem circular that if values are provided that it only sets value
    // just to be unpacked again, but the intent is to allow things that are
    // allowed to be multiple in general but cannot truly be seem as if multiple works
    // even though only 1 value will be returned.
    const value = providedValue ?? valueListToString(values);

    if (!field)
        return (
            <>
                <TextField
                    variant="outlined"
                    label={title}
                    className={`${classes.fullWidth} ${className ?? ''}`}
                    style={style}
                    value={value ?? ''}
                    disabled
                />
                <Typography variant="caption" />
            </>
        );

    if (field.input === 'numeric')
        return (
            <>
                <TextField
                    variant="outlined"
                    label={title}
                    type="text"
                    className={`${classes.fullWidth} ${className ?? ''}`}
                    style={style}
                    onChange={(e) => onChange && onChange(e.target.value, [e.target.value])}
                    value={value ?? ''}
                />
                <Typography variant="caption">Numeric Condition Field. You may use conditional operators.</Typography>
            </>
        );

    const valueLabelPairs = (field.values ?? []).map<LabelValue>((val, idx) => ({
        value: val,
        label: field.labels?.[idx] ?? `${value}`,
    }));

    const stringToValuePairList = (val?: string | string[]) =>
        stringToValueList(val)
            ?.map((v) => valueLabelPairs.find((p) => p.value === v) as LabelValue)
            .filter((v) => !!v) ?? [];

    // TODO: Allow multiple
    if (field.input === 'typeahead' && valueLabelPairs.length > 0)
        return (
            <>
                <Autocomplete<LabelValue, boolean>
                    value={
                        allowMultiple
                            ? stringToValuePairList(value ?? values)
                            : valueLabelPairs.find((p) => p.value === value) ?? null
                    }
                    options={valueLabelPairs}
                    disableCloseOnSelect={allowMultiple}
                    multiple={allowMultiple}
                    onChange={(_, o) => {
                        if (Array.isArray(o)) {
                            if (onChange) {
                                const vals = o.map((x) => x.value);
                                onChange(valueListToString(vals), vals);
                            }
                        } else if (onChange) onChange(o?.value, o?.value ? [o.value] : []);
                    }}
                    getOptionLabel={(o) => o.label}
                    getOptionSelected={(opt, val) => opt.value === val.value}
                    renderInput={(params) => <TextField {...params} label={title} variant="outlined" />}
                />

                <Typography variant="caption">
                    Typeahead: {allowMultiple ? 'You may select many options.' : 'You may only select one option.'}
                </Typography>
            </>
        );

    if ((field.input === 'multiselect' || field.input === 'select') && valueLabelPairs.length > 0)
        return (
            <>
                <Select
                    label={title}
                    variant="outlined"
                    multiple={allowMultiple}
                    value={allowMultiple ? stringToValueList(value ?? values) : value}
                    onChange={(e) => {
                        const val = e.target.value as string | string[];
                        if (Array.isArray(val)) {
                            if (onChange) onChange(valueListToString(val), val);
                        } else if (onChange) onChange(val, [val]);
                    }}
                >
                    {valueLabelPairs.map(({ value: val, label }) => (
                        <MenuItem value={val}>{label}</MenuItem>
                    ))}
                </Select>
                <Typography variant="caption">
                    {allowMultiple
                        ? 'Multiselect: You may select many options.'
                        : 'Select: You may only select one option.'}
                </Typography>
            </>
        );

    return (
        <>
            <TextField
                label={title}
                variant="outlined"
                className={`${classes.fullWidth} ${className ?? ''}`}
                style={style}
                onChange={(e) => onChange && onChange(e.target.value, [e.target.value])}
                value={value ?? ''}
            />
            <Typography variant="caption">
                General text field. Enter an exact value. This value is not case sensitive.
            </Typography>
        </>
    );
};

export default FieldInput;
