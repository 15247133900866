import { JOB_UPDATE_FREQUENCY, API_ENDPOINT } from 'config';

import { Query, FieldIds } from 'models/query';
import { CalculatorManager, AuthManager } from 'models';

export type Status = 'Pending' | 'Processing' | 'Ready' | 'Error';

export declare module API {
    export interface Message {
        Message: string;
    }

    export interface JobSummary {
        id: string;
        name: string;
        description: string;
        type: CalculatorManager.CalculatorId;
        status: Status;
        submitted: string; // Date
        started?: string | null; // Date
        completed?: string | null; // Date
    }

    export interface Job extends JobSummary {
        query?: Query;
        modifications: Modification[];
        error?: string;
        results?: QueryResults;
        processArn?: string;
        csv?: string;
        user: User;
    }

    export interface ListJobs {
        Items: JobSummary[];
    }

    export interface GetJob {
        Item: Job;
    }

    export interface DataUrl {
        url: string;
    }
}

export interface User {
    id: string;
    name: string;
    email: string;
}

export interface JobSummary {
    id: string;
    name: string;
    description: string;
    type: CalculatorManager.CalculatorId;
    status: Status;
    submitted: number;
    started?: number | null;
    completed?: number | null;
}

export interface Job extends JobSummary {
    query?: Query;
    modifications: Modification[];
    error?: string;
    results?: QueryResults;
    processArn?: string;
    csv?: string;
    user: User;
}

export type NewJob = Omit<Partial<Job>, 'id' | 'submitted' | 'status' | 'started' | 'completed' | 'error'>;

export interface Modification {
    where: string;
    fieldValues: Partial<Record<FieldIds, string>>;
    set: Partial<
        Record<
            FieldIds,
            | [Partial<Record<FieldIds, string>>, string, number][]
            | [Partial<Record<FieldIds, string>>, string, string][]
        >
    >;
}

export interface Aggregate {
    name: string;
    value: number;
    diff?: number;
    units?: string;
}

export interface QueryResults {
    length: number; // in km
    count: number;
    filesize: number; // In bytes
    duration: number; // in ms
    aggregates: Aggregate[];
    ignored: number;
}

let jobCache: JobSummary[] = [];
let lastUpdated = 0;
const timeBetweenUpdate = JOB_UPDATE_FREQUENCY;

const getJobsRaw = async (): Promise<JobSummary[]> => {
    const headers = new Headers({
        Token: AuthManager.getToken() ?? '',
    });
    const response = await fetch(`${API_ENDPOINT}/`, {
        headers,
        mode: 'cors',
    });

    if (!response.ok) return [];

    const root = (await response.json()) as API.ListJobs;

    return root.Items.map<JobSummary>(({ submitted, started, completed, ...item }) => ({
        ...item,
        submitted: new Date(submitted).getTime(),
        started: started ? new Date(started).getTime() : null,
        completed: completed ? new Date(completed).getTime() : null,
    }));
};

export const getJob = async (id: number | string): Promise<Job | null> => {
    const headers = new Headers({
        Token: AuthManager.getToken() ?? '',
    });
    const response = await fetch(`${API_ENDPOINT}/job?id=${id}`, {
        headers,
    });

    if (!response.ok) return null;

    const root = (await response.json()) as API.GetJob;

    const { submitted, started, completed, ...item } = root.Item;

    return {
        ...item,
        submitted: new Date(submitted).getTime(),
        started: started ? new Date(started).getTime() : null,
        completed: completed ? new Date(completed).getTime() : null,
    };
};

export const getJobs = async (skipCache?: boolean): Promise<JobSummary[]> => {
    if (skipCache || Date.now() - lastUpdated >= timeBetweenUpdate) {
        const jobs = await getJobsRaw();
        jobCache = jobs;
        lastUpdated = Date.now();
        return jobs;
    }
    return jobCache;
};

export const getJobSummaryById = async (id: string | number) => (await getJobs()).find((job) => job.id === `${id}`);

export const addJob = async (job: NewJob): Promise<Job | null> => {
    const headers = new Headers({
        Token: AuthManager.getToken() ?? '',
    });
    // Split out only the relevant fields as
    // this function may have more than what is expected
    const { name, description, modifications, query, type } = job;

    const response = await fetch(`${API_ENDPOINT}/`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            name,
            description,
            modifications,
            query,
            type,
        }),
    });

    if (!response.ok) return null;

    const root = (await response.json()) as API.GetJob;

    const { submitted, started, completed, ...item } = root.Item;

    return {
        ...item,
        submitted: new Date(submitted).getTime(),
        started: started ? new Date(started).getTime() : null,
        completed: completed ? new Date(completed).getTime() : null,
    };
};

export const deleteJob = async (id: number | string): Promise<Job | null> => {
    const headers = new Headers({
        Token: AuthManager.getToken() ?? '',
    });
    const response = await fetch(`${API_ENDPOINT}/job?id=${id}`, {
        method: 'DELETE',
        headers,
    });

    if (!response.ok) return null;

    const root = (await response.json()) as API.GetJob;

    const { submitted, started, completed, ...item } = root.Item;

    return {
        ...item,
        submitted: new Date(submitted).getTime(),
        started: started ? new Date(started).getTime() : null,
        completed: completed ? new Date(completed).getTime() : null,
    };
};

export const cancelJob = async (id: number | string): Promise<Job | null> => {
    const headers = new Headers({
        Token: AuthManager.getToken() ?? '',
    });
    const response = await fetch(`${API_ENDPOINT}/cancel?id=${id}`, {
        method: 'POST',
        headers,
    });

    if (!response.ok) return null;

    const root = (await response.json()) as API.GetJob;

    const { submitted, started, completed, ...item } = root.Item;

    return {
        ...item,
        submitted: new Date(submitted).getTime(),
        started: started ? new Date(started).getTime() : null,
        completed: completed ? new Date(completed).getTime() : null,
    };
};

const isUrl = (obj: any): obj is API.DataUrl => !!obj.url;

export const getDataLink = async (id: number | string): Promise<string | null> => {
    const headers = new Headers({
        Token: AuthManager.getToken() ?? '',
    });
    const response = await fetch(`${API_ENDPOINT}/data?id=${id}`, {
        method: 'GET',
        headers,
    });

    if (!response.ok) return null;

    const root = (await response.json()) as API.DataUrl | API.Message;

    if (!isUrl(root)) return null;

    return root.url;
};
