import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Theme, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        root: {},
        dialog: {
            padding: spacing(0),
        },
    })
);

interface Props {
    onConfirm: () => void;
    onClose: () => void;
    message: string;
    open: boolean;
}

const ConfirmDialog: React.FunctionComponent<Props> = ({ onConfirm, onClose, message, open }) => {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose} className={classes.root}>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button onClick={onConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
