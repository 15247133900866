import React, { useEffect, useState } from 'react';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { CheckCircleOutline, AccessTime, ErrorOutline, Timelapse, Loop, KeyboardArrowRight } from '@material-ui/icons';

import ListItem from 'components/ListItem';

import { JobManager } from 'models';
import { getTimeAgoString, sleep } from 'utils';

const useStyles = makeStyles(() =>
    createStyles({
        icon: {
            fontSize: 32,
        },
    })
);

interface Props {
    job: JobManager.JobSummary;
    onClick?: () => void;
    style?: React.CSSProperties;
    className?: string;
}

const JobListItem: React.FunctionComponent<Props> = ({ job, onClick, style, className }) => {
    const classes = useStyles();
    const theme = useTheme();

    const [counter, setCounter] = useState(0);

    const getIcon = (status?: JobManager.Status | null): JSX.Element => {
        const color = status ? theme.palette.status[status] : '#000000';
        switch (status) {
            case 'Ready':
                return <CheckCircleOutline style={{ color }} className={classes.icon} />;
            case 'Error':
                return <ErrorOutline style={{ color }} className={classes.icon} />;
            case 'Pending':
                return <AccessTime style={{ color }} className={classes.icon} />;
            case 'Processing':
                return <Timelapse style={{ color }} className={classes.icon} />;
            default:
                return <Loop style={{ color }} className={classes.icon} />;
        }
    };

    const getSecondaryText = (): string => {
        if (!job) return 'Job not initialised';

        if (job.status === 'Ready' && job.completed) return `Completed ${getTimeAgoString(job.completed)}`;

        if (job.status === 'Error') return `Failed ${getTimeAgoString(job.started ?? job.submitted)}`;

        if (job.status === 'Pending') return `Submitted ${getTimeAgoString(job.submitted)}`;

        if (job.status === 'Processing' && job.submitted) return `Begun processing ${getTimeAgoString(job.submitted)}`;

        return 'Loading...';
    };

    useEffect(() => {
        const delay = getSecondaryText().indexOf('seconds') === -1 ? 60_000 : 1000;

        let currentTimeout: any;

        sleep(delay, (timeout) => {
            currentTimeout = timeout;
        }).then(() => setCounter(counter + 1));

        return () => {
            clearTimeout(currentTimeout);
        };
    }, [counter]);

    return (
        <ListItem
            primaryText={job.name}
            secondaryText={getSecondaryText()}
            onClick={onClick}
            statusIcon={getIcon(job?.status)}
            actionIcon={<KeyboardArrowRight className={classes.icon} />}
            style={style}
            className={className}
        />
    );
};

export default JobListItem;
