import React, { useState, useEffect } from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, TextField, Button } from '@material-ui/core';

import { JobManager } from 'models';

import Page from 'components/Page';

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        root: {
            display: 'grid',
            gridTemplateRows: 'auto auto 1fr auto',
            height: '100%',
        },
        input: {
            margin: spacing(1),
        },
        button: {
            padding: spacing(1),
        },
        buttonContainer: {
            padding: spacing(1),
            display: 'grid',
            alignItems: 'end',
        },
    })
);

interface Props extends RouteComponentProps<{ calc?: string }> {
    onSectionUpdate: (title: string, description: string) => void;
    onNextSection: () => void;
    job: JobManager.NewJob;
}

const JobNewDetails: React.FunctionComponent<Props> = ({ onSectionUpdate, onNextSection, job }) => {
    const classes = useStyles();

    const [title, setTitle] = useState(job.name ?? '');
    const [description, setDescription] = useState(job.description ?? '');

    useEffect(() => {
        onSectionUpdate(title, description);
    }, [title, description]);

    useEffect(() => {
        if (job.name) setTitle(job.name);
        if (job.description) setDescription(job.description);
    }, [job]);

    return (
        <Page
            title="Job Details"
            subtitle="Enter title and description"
            footer={
                <Button className={classes.button} variant="contained" disabled={!title} onClick={onNextSection}>
                    Save &amp; Next
                </Button>
            }
        >
            <div className={classes.root}>
                <TextField
                    className={classes.input}
                    variant="outlined"
                    label="Title"
                    helperText="A short title to identify this job"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                />

                <TextField
                    className={classes.input}
                    variant="outlined"
                    label="Description"
                    helperText="A short description to convey the intent of this job"
                    multiline
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                />
            </div>
        </Page>
    );
};

export default JobNewDetails;
