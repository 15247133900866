/* eslint-disable no-template-curly-in-string */
import { ReportConfig } from 'models/export';
import { ENV } from 'config';

export const AusRAPStarRatingsReport: ReportConfig.Root = {
    name: 'AusRAP Star Ratings',
    summary: 'Export AusRAP Star Ratings Results.',
    pages: [
        {
            header: {
                elements: [
                    {
                        type: 'image',
                        location: {
                            x: {
                                align: 'left',
                            },
                            y: {
                                align: 'top',
                            },
                        },
                        width: 190,
                        url: '/images/gradient-header.png',
                    },
                    {
                        type: 'image',
                        location: {
                            x: {
                                align: 'left',
                                offset: 10,
                            },
                            y: {
                                align: 'top',
                                offset: 10,
                            },
                        },
                        scale: 0.12,
                        url: '/images/TfNSW-CRS-white-logo.png',
                    },
                    {
                        type: 'text',
                        location: {
                            x: {
                                align: 'right',
                                offset: -20,
                            },
                            y: {
                                align: 'top',
                                offset: 25,
                            },
                        },
                        text: '${title}',
                        color: '#ffffff',
                        fontSize: 30,
                        fontStyle: 'bold',
                    },
                    {
                        type: 'text',
                        location: {
                            x: {
                                align: 'right',
                                offset: -10,
                            },
                            y: {
                                align: 'top',
                                offset: 35,
                            },
                        },
                        color: '#ffffff',
                        text: '${subtitle}',
                        fontSize: 16,
                    },
                ],
            },
            elements: [
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'center',
                        },
                        y: {
                            align: 'center',
                            offset: -30,
                        },
                    },
                    text: 'Disclaimer',
                    fontSize: 14,
                },
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'center',
                            offset: 8,
                        },
                        y: {
                            align: 'center',
                            offset: 10,
                        },
                    },
                    width: 120,
                    text: 'This application is presented by the Safer Roads team for the purpose of disseminating road safety information to the Transport cluster. This application may include data sourced or derived from other business units of the Transport cluster. The Safer roads team has taken great care to ensure the information is as correct and accurate as possible. Information may be changed or updated without notice. The Safer Roads team does not guarantee, and accepts liability whatsoever arising from, or connected to, the use of any material on this application or on any linked site. This application is not a substitute for independent road safety advice and users should obtain appropriate road safety advice relevant to their particular circumstances. The data supplied is not to be used for any other purpose than that stated when being supplied and it is under no circumstance to be on-supplied to any other party, internal or external.',
                    fontSize: 11,
                },
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'center',
                        },
                        y: {
                            align: 'bottom',
                            offset: -70,
                        },
                    },
                    text: 'Published by',
                    fontSize: 14,
                },
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'center',
                        },
                        y: {
                            align: 'bottom',
                            offset: -70,
                        },
                    },
                    text: 'Published by',
                    fontSize: 14,
                },
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'center',
                        },
                        y: {
                            align: 'bottom',
                            offset: -60,
                        },
                    },
                    text: '${user_name}',
                    fontSize: 14,
                },
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'center',
                        },
                        y: {
                            align: 'bottom',
                            offset: -50,
                        },
                    },
                    text: '${long_date}',
                    fontSize: 14,
                },
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'center',
                            offset: 8,
                        },
                        y: {
                            align: 'top',
                            offset: 70,
                        },
                    },
                    width: 120,
                    text:
                        ENV === 'prod'
                            ? ''
                            : 'This PDF Document is only a demo and only exists to show the capability while this document is still being designed. \nFeedback on what should be included is welcome.',
                    fontSize: 11,
                },
            ],
        },
        {
            elements: [
                {
                    type: 'text',
                    id: 'query-summary-title',
                    text: 'Query Summary',
                    fontSize: 14,
                    width: 100,
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'top',
                            offset: 25,
                        },
                    },
                },
                {
                    type: 'text',
                    id: 'summary',
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'under',
                            id: 'query-summary-title',
                            offset: 0,
                        },
                    },
                    width: 140,
                    text: '${summary}',
                    fontSize: 12,
                },
                {
                    type: 'text',
                    id: 'selection-criteria-title',
                    text: 'Selection Criteria',
                    fontSize: 14,
                    width: 100,
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'under',
                            offset: 10,
                            id: 'summary',
                        },
                    },
                },
                {
                    type: 'html',
                    id: 'selection-criteria',
                    refId: 'selection-criteria',
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'under',
                            offset: 0,
                            id: 'selection-criteria-title',
                        },
                    },
                },
                {
                    type: 'text',
                    id: 'modifications-title',
                    text: 'Modifications',
                    fontSize: 14,
                    width: 100,
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'under',
                            offset: 10,
                            id: 'selection-criteria',
                        },
                    },
                },
                {
                    type: 'html',
                    id: 'modifications',
                    refId: 'modifications',
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'under',
                            offset: 0,
                            id: 'modifications-title',
                        },
                    },
                },
            ],
        },
        {
            elements: [
                {
                    type: 'text',
                    id: 'query-results-title',
                    text: 'Query Result Summary',
                    fontSize: 14,
                    width: 100,
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'top',
                            offset: 25,
                        },
                    },
                },
                {
                    type: 'html',
                    id: 'query-results',
                    refId: 'query-results',
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'under',
                            offset: 0,
                            id: 'query-results-title',
                        },
                    },
                },
                {
                    type: 'text',
                    id: 'aggregatesMainTitle',
                    text: 'Calculation Aggregates General',
                    fontSize: 14,
                    width: 100,
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'under',
                            offset: 15,
                            id: 'query-results',
                        },
                    },
                },
                {
                    type: 'html',
                    refId: 'aggregates-display',
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'under',
                            offset: 0,
                            id: 'aggregatesMainTitle',
                        },
                    },
                },
            ],
        },
        {
            elements: [
                {
                    type: 'text',
                    id: 'smoothedChartTitle',
                    text: 'AusRAP Star Ratings Smoothed',
                    fontSize: 14,
                    width: 100,
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'top',
                            offset: 25,
                        },
                    },
                },
                {
                    type: 'custom_image',
                    id: 'smoothedChart',
                    refId: 'ausrap_smoothed_chart',
                    width: 160,
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'under',
                            offset: 0,
                            id: 'smoothedChartTitle',
                        },
                    },
                },
                {
                    type: 'text',
                    id: 'rawChartTitle',
                    text: 'AusRAP Star Ratings Raw',
                    fontSize: 14,
                    width: 100,
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'under',
                            offset: 15,
                            id: 'smoothedChart',
                        },
                    },
                },
                {
                    type: 'custom_image',
                    refId: 'ausrap_raw_chart',
                    width: 160,
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'under',
                            offset: 0,
                            id: 'rawChartTitle',
                        },
                    },
                },
            ],
        },
        {
            elements: [
                {
                    type: 'text',
                    id: 'aggregatesSmoothedTitle',
                    text: 'Calculation Aggregates Smoothed',
                    fontSize: 14,
                    width: 100,
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'top',
                            offset: 25,
                        },
                    },
                },
                {
                    type: 'html',
                    refId: 'aggregates-smoothed',
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'under',
                            offset: 0,
                            id: 'aggregatesSmoothedTitle',
                        },
                    },
                },
            ],
        },
        {
            elements: [
                {
                    type: 'text',
                    id: 'aggregatesRawTitle',
                    text: 'Calculation Aggregates Raw',
                    fontSize: 14,
                    width: 100,
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'top',
                            offset: 25,
                        },
                    },
                },
                {
                    type: 'html',
                    refId: 'aggregates-raw',
                    location: {
                        x: {
                            align: 'left',
                            offset: 14,
                        },
                        y: {
                            align: 'under',
                            offset: 0,
                            id: 'aggregatesRawTitle',
                        },
                    },
                },
            ],
        },
    ],
    defaults: {
        paperSize: 'A4',
        paperOrientation: 'portrait',
        fontName: 'helvetica',
        header: {
            elements: [
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'right',
                            offset: -5,
                        },
                        y: {
                            align: 'top',
                            offset: 10,
                        },
                    },
                    color: '#666666',
                    fontStyle: 'bold',
                    text: '${title}',
                    fontSize: 12,
                },
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'right',
                            offset: -2,
                        },
                        y: {
                            align: 'top',
                            offset: 15,
                        },
                    },
                    color: '#666666',
                    text: '${subtitle}',
                    fontSize: 10,
                },
            ],
        },
        footer: {
            elements: [
                {
                    type: 'image',
                    location: {
                        x: {
                            align: 'left',
                        },
                        y: {
                            align: 'bottom',
                        },
                    },
                    width: 190,
                    url: '/images/gradient-twist-footer.png',
                },
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'right',
                            offset: -10,
                        },
                        y: {
                            align: 'bottom',
                            offset: -1,
                        },
                    },
                    fontSize: 9,
                    color: '#fff',
                    text: 'Page ${page_number} of ${total_pages}',
                },
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'left',
                            offset: 10,
                        },
                        y: {
                            align: 'bottom',
                            offset: -3,
                        },
                    },
                    fontSize: 9,
                    fontStyle: 'bold',
                    color: '#fff',
                    text: 'Centre for Road Safety',
                },
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'left',
                            offset: 10,
                        },
                        y: {
                            align: 'bottom',
                            offset: 1,
                        },
                    },
                    fontSize: 9,
                    color: '#fff',
                    text: '18 Lee Street, Chippendale NSW 2008 | PO Box K659, Haymarket NSW 1240',
                },
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'left',
                            offset: 50,
                        },
                        y: {
                            align: 'bottom',
                            offset: -3,
                        },
                    },
                    fontStyle: 'bold',
                    fontSize: 9,
                    color: '#fff',
                    text: 'Tel: ',
                },
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'left',
                            offset: 57,
                        },
                        y: {
                            align: 'bottom',
                            offset: -3,
                        },
                    },
                    fontSize: 9,
                    color: '#fff',
                    text: '8202 2200',
                },
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'left',
                            offset: 75,
                        },
                        y: {
                            align: 'bottom',
                            offset: -3,
                        },
                    },
                    fontStyle: 'bold',
                    fontSize: 9,
                    color: '#fff',
                    text: 'Fax: ',
                },
                {
                    type: 'text',
                    location: {
                        x: {
                            align: 'left',
                            offset: 82,
                        },
                        y: {
                            align: 'bottom',
                            offset: -3,
                        },
                    },
                    fontSize: 9,
                    color: '#fff',
                    text: '8202 2209',
                },
            ],
        },
    },
};

export default AusRAPStarRatingsReport;
