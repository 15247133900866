// eslint-disable-next-line import/prefer-default-export

type ENVVALUES = 'dev' | 'test' | 'uat' | 'prod';

export const ENV: ENVVALUES = (process.env.REACT_APP_ENV?.toLowerCase() as ENVVALUES) ?? 'dev';

/**
 * Cache time for getting Jobs. Allows the same get Jobs function to be used
 * everywhere without worrying about repeated unnecessary requests.
 */
export const JOB_UPDATE_FREQUENCY = 15_000; // 15 seconds

export const AUTH_TOKEN_REFRESH_TIME = 15 * 60 * 1000; // 15 minutes

export const PORTAL_URL = process.env.REACT_APP_PORTAL_URL ?? 'https://geohub.transport.nsw.gov.au/portal';

export const MAPSERVER_LAYER_URL =
    process.env.REACT_APP_MAPSERVER_LAYER_URL ??
    'https://geohub.transport.nsw.gov.au/federated/rest/services/SRRA_TEST/SRRA_TEST_2022/MapServer/0';

export const PORTAL_SELF_ENDPOINT = `${PORTAL_URL}/sharing/rest/community/self`;

export const LOCALSTORAGE_TOKEN = 'token';
export const LOCALSTORAGE_REFRESH_TOKEN = 'refresh_token';

/**
 * Cleaner config logic, if given env var, use that, otherwise
 * fall back to known urls based on env
 */
const getAPIEndpoint = () => {
    if (process.env.REACT_APP_API_ENDPOINT) return process.env.REACT_APP_API_ENDPOINT;

    if (ENV === 'uat') return 'https://xdk5c2ufsh.execute-api.ap-southeast-2.amazonaws.com/Prod';

    if (ENV === 'prod') return 'https://rdrg9rbz41.execute-api.ap-southeast-2.amazonaws.com/Prod/';

    // ENV == dev or test
    // Pointing to AnyCloud dev deployment
    return 'https://md3sl2wr5i.execute-api.ap-southeast-2.amazonaws.com/Prod';
};

export const API_ENDPOINT = getAPIEndpoint();

const getOAuthConfig = () => {
    const AUTHORISATION_ENDPOINT = `${PORTAL_URL}/sharing/rest/oauth2/authorize`;
    const TOKEN_ENDPOINT = `${PORTAL_URL}/sharing/rest/oauth2/token`;

    if (ENV === 'prod')
        return {
            AUTHORISATION_ENDPOINT,
            TOKEN_ENDPOINT,
            CLIENT_ID: 'UYwIidzlolKnjQ6B',
            REDIRECT_URI: 'https://srra-calculator.geohub.transport.nsw.gov.au',
        };

    if (ENV === 'uat')
        return {
            AUTHORISATION_ENDPOINT,
            TOKEN_ENDPOINT,
            CLIENT_ID: 'YmS9ofYsMdtl7fWu',
            REDIRECT_URI: 'https://srra-calculator.geohubnp.transport.nsw.gov.au',
        };

    return {
        AUTHORISATION_ENDPOINT,
        TOKEN_ENDPOINT,
        CLIENT_ID: 'YmS9ofYsMdtl7fWu',
        // Make sure your origin is configured to be allowed
        // By the App with the above CLIENT_ID
        REDIRECT_URI: window.location.origin,
    };
};

/**
 * Contains OAuth configuration
 */
export const OAUTH = getOAuthConfig();
