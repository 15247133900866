import React, { useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { RouteComponentProps } from 'react-router-dom';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Typography, Button, CircularProgress } from '@material-ui/core';
import { CheckCircleOutline, AccessTime, ErrorOutline, Timelapse, Loop, Done, SaveAlt } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

import Page from 'components/Page';
import Chart from 'components/Chart';
import ModificationDetails from 'components/ModificationDetails';

import { useAppSelector, useAppDispatch } from 'hooks';
import { jobActions } from 'slices/job';
import { JobManager, CalculatorManager, AuthManager, QueryManager } from 'models';
import { PDFReport } from 'models/export';
import { AusRAPStarRatingsReport } from 'models/reports';
import { getTimeTextFromMillis, round, sleep } from 'utils';

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        overview: {
            borderRadius: spacing(0.5),
        },
        pdfOverview: {
            width: 620,
            borderRadius: spacing(0.5),
            paddingTop: 2,
        },
        overviewRow: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: `${spacing(0.5)}px ${spacing(1)}px`,
            backgroundColor: grey[300],
            '&:nth-of-type(odd)': {
                backgroundColor: grey[100],
            },
        },
        overviewRowCompact: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: `1px ${spacing(1)}px`,
            backgroundColor: grey[300],
            '&:nth-of-type(odd)': {
                backgroundColor: grey[100],
            },
        },
        inlineTextIcon: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            fontSize: 20,
            paddingRight: spacing(1),
        },
        descriptions: {
            padding: spacing(1),
        },
        descriptionText: {
            paddingLeft: spacing(2),
        },
        modifications: {
            padding: spacing(1),
        },
        actions: {
            display: 'grid',
            gridAutoFlow: 'column',
            gap: spacing(2),
        },
    })
);

const starRatingLabels: Record<string, string> = {
    '1 Star old': '1 Star',
    '2 Star old': '2 Star',
    '3 Star old': '3 Star',
    '4 Star old': '4 Star',
    '5 Star old': '5 Star',
    '1 Star new': '1 Star',
    '2 Star new': '2 Star',
    '3 Star new': '3 Star',
    '4 Star new': '4 Star',
    '5 Star new': '5 Star',
};

const starRatingPriority: Record<string, number> = {
    '1 Star old': 1,
    '2 Star old': 2,
    '3 Star old': 3,
    '4 Star old': 4,
    '5 Star old': 5,
    '1 Star new': 1,
    '2 Star new': 2,
    '3 Star new': 3,
    '4 Star new': 4,
    '5 Star new': 5,
};

const starRatingColours: Record<string, string> = {
    '1': '#000000',
    '2': '#E21736',
    '3': '#F78F1E',
    '4': '#FFED00',
    '5': '#7DDB63',
};

const chartAggregateRawRegexp = /^From ([1-9]) to ([1-9]) Star$/;
const chartAggregateSmoothedRegexp = /^From ([1-9]) to ([1-9]) Star Smoothed$/;

const JobResultsPage: React.FunctionComponent<RouteComponentProps<{ id: string }>> = ({ match }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const { fields } = useAppSelector((state) => state.fields);
    const { selectedJob: job, selectedJobState: jobState } = useAppSelector((state) => state.jobs);

    const [chartRawRef, setChartRawRef] = useState<React.RefObject<HTMLCanvasElement>>();
    const [chartSmoothedRef, setChartSmoothedRef] = useState<React.RefObject<HTMLCanvasElement>>();
    const [generating, setGenerating] = useState(false);

    const calculator = CalculatorManager.getById(job?.type);

    const color = job && job.status ? theme.palette.status[job.status] : '#000000';

    const id = +match.params?.id;

    const fileSizeInMb = (job?.results?.filesize ?? 0) / (1024 * 1024);

    const chartDataSmoothed = job?.results?.aggregates
        .map((aggregate) => {
            const result = chartAggregateSmoothedRegexp.exec(aggregate.name);
            if (result == null) return null;

            return {
                from: `${result[1]} Star old`,
                to: `${result[2]} Star new`,
                flow: aggregate.value,
            };
        })
        .filter((x) => x && x.flow > 0);

    const chartDataRaw = job?.results?.aggregates
        .map((aggregate) => {
            const result = chartAggregateRawRegexp.exec(aggregate.name);
            if (result == null) return null;

            return {
                from: `${result[1]} Star old`,
                to: `${result[2]} Star new`,
                flow: aggregate.value,
            };
        })
        .filter((x) => x && x.flow > 0);

    const getIcon = (): React.ReactNode => {
        switch (job?.status) {
            case 'Ready':
                return <CheckCircleOutline style={{ color }} className={classes.icon} />;
            case 'Error':
                return <ErrorOutline style={{ color }} className={classes.icon} />;
            case 'Pending':
                return <AccessTime style={{ color }} className={classes.icon} />;
            case 'Processing':
                return <Timelapse style={{ color }} className={classes.icon} />;
            default:
                return <Loop style={{ color }} className={classes.icon} />;
        }
    };

    const downloadCSV = () => {
        JobManager.getDataLink(id).then((url) => {
            if (url) window.open(url, ' Star newtab');
        });
    };

    const generateResultsTable = (aggregates: JobManager.Aggregate[], compact = false) =>
        aggregates.map((aggregate) => (
            <div key={aggregate.name} className={compact ? classes.overviewRowCompact : classes.overviewRow}>
                <Typography variant="subtitle1">
                    <b>{aggregate.name}</b>
                </Typography>
                <Typography variant="subtitle1">
                    {round(aggregate.value, 3)}
                    {aggregate.units}
                    {aggregate.diff
                        ? ` (${aggregate.diff > 0 ? '+' : ''}${round(aggregate.diff, 3)}${aggregate.units})`
                        : ''}
                </Typography>
            </div>
        ));

    const generateAusRAPReport = async () => {
        if (job) {
            const user = await AuthManager.getSelf();

            if (user) {
                setGenerating(true);

                const report = new PDFReport(AusRAPStarRatingsReport);

                report.setVariable('title', job.name);
                report.setVariable('subtitle', 'An AusRAP Star Ratings Calculation');
                report.setVariable('user_name', user.fullName);
                report.setVariable('summary', job.description || 'No query description added.');

                report.setCallback('getImageData', async (refId) => {
                    if (refId === 'ausrap_raw_chart' && chartRawRef?.current) {
                        await sleep(100);
                        const chartElement = chartRawRef.current;
                        return chartElement.toDataURL();
                    }

                    if (refId === 'ausrap_smoothed_chart' && chartSmoothedRef?.current) {
                        await sleep(100);
                        const chartElement = chartSmoothedRef.current;
                        return chartElement.toDataURL();
                    }
                    return null;
                });

                report.setCallback('addHTML', async (refId) => {
                    if (refId === 'selection-criteria') {
                        return renderToStaticMarkup(
                            <div className={classes.pdfOverview}>
                                <Typography variant="body1">
                                    {QueryManager.fieldValuesToPrettyWhere(job.query?.fieldValues ?? {}, fields)}
                                </Typography>
                                {job.query?.geometry && (
                                    <Typography variant="body1">
                                        A Geometry also contributed to this selection.
                                    </Typography>
                                )}
                            </div>
                        );
                    }

                    if (refId === 'modifications') {
                        return renderToStaticMarkup(
                            <div className={classes.pdfOverview}>
                                {job.modifications?.map((mod) => (
                                    <ModificationDetails key={`${mod.where}`} mod={mod} fields={fields} isPDF />
                                ))}
                                {job.modifications.length === 0 && (
                                    <div>
                                        <Typography variant="body1">
                                            <b>No Modifications Performed</b>
                                        </Typography>
                                    </div>
                                )}
                            </div>
                        );
                    }

                    if (job && refId === 'query-results') {
                        const dateOptions: any = {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                        };

                        return renderToStaticMarkup(
                            <div className={classes.pdfOverview}>
                                <div className={classes.overviewRow}>
                                    <Typography variant="subtitle1">
                                        <b>Type</b>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {CalculatorManager.getById(job.type)?.name ?? 'N/A'}
                                    </Typography>
                                </div>
                                <div className={classes.overviewRow}>
                                    <Typography variant="subtitle1">
                                        <b>Date Processed</b>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {job.completed != null
                                            ? new Date(job.completed).toLocaleDateString('en-AU', dateOptions)
                                            : ''}
                                    </Typography>
                                </div>
                                <div className={classes.overviewRow}>
                                    <Typography variant="subtitle1">
                                        <b>Selected Features</b>
                                    </Typography>
                                    <Typography variant="subtitle1">{job.results?.count ?? 'N/A'}</Typography>
                                </div>
                                <div className={classes.overviewRow}>
                                    <Typography variant="subtitle1">
                                        <b>Length of Road</b>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {job.results?.length ? `${round(job.results.length, 3)} km` : 'N/A'}
                                    </Typography>
                                </div>
                            </div>
                        );
                    }

                    if (job?.results?.aggregates != null && calculator != null) {
                        if (refId === 'aggregates-display') {
                            const aggregates = calculator.aggregatesToDisplay
                                .map((name) => job?.results?.aggregates.find((agg) => agg.name === name))
                                .filter((x) => !!x) as JobManager.Aggregate[];
                            return renderToStaticMarkup(
                                <div className={classes.pdfOverview}>{generateResultsTable(aggregates)}</div>
                            );
                        }

                        if (refId === 'aggregates-smoothed') {
                            const aggregates = job.results.aggregates.filter(
                                (agg) =>
                                    !calculator.aggregatesToDisplay.includes(agg.name) &&
                                    !agg.name.includes('error') &&
                                    agg.name.includes('Smoothed')
                            ) as JobManager.Aggregate[];

                            return renderToStaticMarkup(
                                <div className={classes.pdfOverview}>{generateResultsTable(aggregates, true)}</div>
                            );
                        }

                        if (refId === 'aggregates-raw') {
                            const aggregates = job.results.aggregates.filter(
                                (agg) =>
                                    !calculator.aggregatesToDisplay.includes(agg.name) &&
                                    !agg.name.includes('error') &&
                                    !agg.name.includes('Smoothed')
                            ) as JobManager.Aggregate[];

                            return renderToStaticMarkup(
                                <div className={classes.pdfOverview}>{generateResultsTable(aggregates, true)}</div>
                            );
                        }
                    }

                    return null;
                });

                const pdf = await report.generateReport();

                if (pdf) {
                    await pdf?.save(job.name, { returnPromise: true });
                } else {
                    console.error('No PDF Generated');
                }

                setGenerating(false);
            }
        }
    };

    useEffect(() => {
        if (id !== null) {
            dispatch(jobActions.getJob(id));
        }
    }, [match.params?.id]);

    return (
        <Page
            title="Job Results"
            subtitle={job?.name}
            loading={!job && jobState === 'loading'}
            backUrl={`/job/${match.params?.id}`}
            onRefresh={() => {
                dispatch(jobActions.getJob(id));
            }}
            refreshing={jobState === 'loading'}
            footer={
                <div className={classes.actions}>
                    <Button
                        variant="outlined"
                        onClick={generateAusRAPReport}
                        disabled={job?.results == null || job?.results?.count === 0}
                    >
                        {generating ? <CircularProgress size={22} style={{ marginRight: 6 }} /> : <SaveAlt />} Download
                        PDF
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={downloadCSV}
                        disabled={job?.results == null || job?.results?.count === 0}
                    >
                        <Done /> Download CSV
                        {fileSizeInMb && fileSizeInMb >= 0.1 ? `(${round(fileSizeInMb, 1)} Mb)` : ''}
                    </Button>
                </div>
            }
            header={
                <>
                    {!job && !(jobState === 'loading' || jobState === 'init') && (
                        <Typography variant="subtitle1">
                            <b>Job Not Found</b>
                        </Typography>
                    )}
                    {!job && jobState === 'error' && (
                        <Typography variant="subtitle1">
                            <b>Error Loading Job</b>
                        </Typography>
                    )}
                    {job && (
                        <div className={classes.overview}>
                            <div className={classes.overviewRow}>
                                <Typography variant="subtitle1">
                                    <b>Status</b>
                                </Typography>
                                <Typography variant="subtitle1" className={classes.inlineTextIcon} style={{ color }}>
                                    {getIcon()}
                                    <b>{job.status}</b>
                                </Typography>
                            </div>
                            <div className={classes.overviewRow}>
                                <Typography variant="subtitle1">
                                    <b>Type</b>
                                </Typography>
                                <Typography variant="subtitle1">
                                    {CalculatorManager.getById(job.type)?.name ?? 'N/A'}
                                </Typography>
                            </div>
                            <div className={classes.overviewRow}>
                                <Typography variant="subtitle1">
                                    <b>Execution Time</b>
                                </Typography>
                                <Typography variant="subtitle1">
                                    {job.results?.duration ? getTimeTextFromMillis(job.results.duration, '') : 'N/A'}
                                </Typography>
                            </div>
                            <div className={classes.overviewRow}>
                                <Typography variant="subtitle1">
                                    <b>Selected Features</b>
                                </Typography>
                                <Typography variant="subtitle1">{job.results?.count ?? 'N/A'}</Typography>
                            </div>
                            <div className={classes.overviewRow}>
                                <Typography variant="subtitle1">
                                    <b>Length of Road</b>
                                </Typography>
                                <Typography variant="subtitle1">
                                    {job.results?.length ? `${round(job.results.length, 3)} km` : 'N/A'}
                                </Typography>
                            </div>
                        </div>
                    )}
                </>
            }
        >
            {job && job.results && job.results?.count === 0 && (
                <>
                    <div className={classes.modifications}>
                        <Typography variant="h6" align="center">
                            No 100m segments selected
                        </Typography>
                    </div>
                </>
            )}
            {job && job.results && job.results?.count > 0 && calculator && (
                <>
                    <div className={classes.modifications}>
                        <Typography variant="h6" align="center">
                            Results
                        </Typography>
                        <div className={classes.overview}>
                            {generateResultsTable(
                                calculator.aggregatesToDisplay
                                    .map((name) => job?.results?.aggregates.find((agg) => agg.name === name))
                                    .filter((x) => !!x) as JobManager.Aggregate[]
                            )}
                        </div>
                        <div className={classes.modifications}>
                            {job.type === 'ausrap' && (
                                <>
                                    <Typography variant="h6" align="center">
                                        AusRAP Star Ratings Smoothed
                                    </Typography>
                                    {chartDataSmoothed && chartDataSmoothed.length > 0 ? (
                                        <Chart
                                            getRef={(ref) => setChartSmoothedRef(ref)}
                                            options={{
                                                type: 'sankey',
                                                data: {
                                                    datasets: [
                                                        {
                                                            data: chartDataSmoothed,
                                                            labels: starRatingLabels,
                                                            priority: starRatingPriority,
                                                            colorMode: 'gradient',
                                                            borderWidth: 0,
                                                            colorFrom: (c: any) =>
                                                                starRatingColours[
                                                                    c.dataset.data[c.dataIndex].from[0] as string
                                                                ],
                                                            colorTo: (c: any) =>
                                                                starRatingColours[
                                                                    c.dataset.data[c.dataIndex].to[0] as string
                                                                ],
                                                        },
                                                    ],
                                                },
                                                plugins: {
                                                    tooltip: {
                                                        callbacks: {
                                                            label: (c: any) => {
                                                                return ` ${c.dataset.data[c.dataIndex].from} → ${
                                                                    c.dataset.data[c.dataIndex].to
                                                                }: ${round(c.dataset.data[c.dataIndex].flow, 2)} km`;
                                                            },
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    ) : (
                                        <Typography variant="h6" align="center">
                                            No smoothed results to chart
                                        </Typography>
                                    )}
                                    <Typography variant="h6" align="center">
                                        AusRAP Star Ratings Raw
                                    </Typography>
                                    {chartDataRaw && chartDataRaw.length > 0 ? (
                                        <Chart
                                            getRef={(ref) => setChartRawRef(ref)}
                                            options={{
                                                type: 'sankey',
                                                data: {
                                                    datasets: [
                                                        {
                                                            data: chartDataRaw,
                                                            labels: starRatingLabels,
                                                            priority: starRatingPriority,
                                                            colorMode: 'gradient',
                                                            borderWidth: 0,
                                                            colorFrom: (c: any) =>
                                                                starRatingColours[
                                                                    c.dataset.data[c.dataIndex].from[0] as string
                                                                ],
                                                            colorTo: (c: any) =>
                                                                starRatingColours[
                                                                    c.dataset.data[c.dataIndex].to[0] as string
                                                                ],
                                                        },
                                                    ],
                                                },
                                                plugins: {
                                                    tooltip: {
                                                        callbacks: {
                                                            label: (c: any) => {
                                                                return ` ${c.dataset.data[c.dataIndex].from} → ${
                                                                    c.dataset.data[c.dataIndex].to
                                                                }: ${round(c.dataset.data[c.dataIndex].flow, 2)} km`;
                                                            },
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    ) : (
                                        <Typography variant="h6" align="center">
                                            No raw results to chart
                                        </Typography>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </Page>
    );
};

export default JobResultsPage;
