import {
    OAUTH,
    LOCALSTORAGE_TOKEN,
    LOCALSTORAGE_REFRESH_TOKEN,
    PORTAL_SELF_ENDPOINT,
    AUTH_TOKEN_REFRESH_TIME,
} from 'config';

declare namespace PortalSelf {
    export interface DisplaySettings {
        itemTypes: string;
    }

    export interface UserMembership {
        username: string;
        memberType: string;
    }

    export interface Group {
        id: string;
        title: string;
        isInvitationOnly: boolean;
        owner: string;
        description: string;
        snippet: string;
        tags: string[];
        phone?: any;
        sortField: string;
        sortOrder: string;
        isViewOnly: boolean;
        thumbnail: string;
        created: number;
        modified: number;
        access: string;
        capabilities: any[];
        isFav: boolean;
        isReadOnly: boolean;
        protected: boolean;
        autoJoin: boolean;
        notificationsEnabled: boolean;
        provider?: any;
        providerGroupName?: any;
        leavingDisallowed: boolean;
        hiddenMembers: boolean;
        displaySettings: DisplaySettings;
        userMembership: UserMembership;
    }

    export interface AppInfo {
        appId: string;
        itemId: string;
        appOwner: string;
        orgId: string;
        appTitle: string;
    }

    export interface User {
        username: string;
        id: string;
        fullName: string;
        firstName: string;
        lastName: string;
        preferredView?: any;
        description?: any;
        email: string;
        userType: string;
        idpUsername: string;
        favGroupId: string;
        lastLogin: number;
        mfaEnabled: boolean;
        validateUserProfile: boolean;
        access: string;
        storageUsage: number;
        storageQuota: number;
        orgId: string;
        role: string;
        privileges: string[];
        adminCategories: string[];
        roleId: string;
        level: string;
        userLicenseTypeId: string;
        disabled: boolean;
        tags: any[];
        culture: string;
        cultureFormat: string;
        region?: any;
        units: string;
        thumbnail?: any;
        created: number;
        modified: number;
        provider: string;
        groups: Group[];
        appInfo: AppInfo;
    }
}

export type User = PortalSelf.User;

export interface PortalErrorResponse {
    error: {
        code: number;
        message: string;
    };
}

let tokenCache: string | null = null;
let refreshTokenCache: string | null;

let selfCache: User | null = null;

let tokenRefreshInterval: any = null;

export const setToken = (t: string) => {
    tokenCache = t;
    localStorage.setItem(LOCALSTORAGE_TOKEN, t);
};

export const getToken = () => {
    if (!tokenCache) tokenCache = localStorage.getItem(LOCALSTORAGE_TOKEN);
    if (tokenCache === 'undefined') tokenCache = null;

    return tokenCache;
};

export const clearToken = () => {
    tokenCache = null;
    localStorage.removeItem(LOCALSTORAGE_TOKEN);
};

export const setRefreshToken = (t: string) => {
    refreshTokenCache = t;
    localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN, t);
};

export const getRefreshToken = () => {
    if (!refreshTokenCache) refreshTokenCache = localStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN);

    return refreshTokenCache;
};

export const clearRefreshToken = () => {
    refreshTokenCache = null;
    localStorage.removeItem(LOCALSTORAGE_REFRESH_TOKEN);
};

export const redirectToAuth = () => {
    const params = new URLSearchParams({
        client_id: OAUTH.CLIENT_ID,
        response_type: 'code',
        redirect_uri: OAUTH.REDIRECT_URI,
        state: JSON.stringify({
            path: `${window.location.pathname}${window.location.search}`,
        }),
        locale: 'en-au',
    });
    window.location.href = `${OAUTH.AUTHORISATION_ENDPOINT}?${params.toString()}`;
};

const getSelfEndpoint = async () => {
    const response = await fetch(`${PORTAL_SELF_ENDPOINT}?f=json&token=${getToken()}`);
    if (!response.ok)
        return {
            error: {
                code: response.status,
                message: response.statusText,
            },
        };
    return response.json();
};

export const isPortalErrorResponse = (response: any): response is PortalErrorResponse => !!response?.error;

export const getSelf = async (): Promise<User | null> => {
    if (selfCache) return selfCache;

    const response = (await getSelfEndpoint()) as User | PortalErrorResponse;

    if (isPortalErrorResponse(response)) return null;

    selfCache = response;
    return response;
};

export const validateToken = async () => !isPortalErrorResponse(await getSelfEndpoint());

export const getNewToken = async () => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
        console.log('Refreshing Token');
        const tokenParams = new URLSearchParams({
            client_id: OAUTH.CLIENT_ID,
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
        });

        const response = await fetch(`${OAUTH.TOKEN_ENDPOINT}?${tokenParams.toString()}`);

        if (response.ok) {
            const data = await response.json();
            if (data.access_token) {
                setToken(data.access_token);
                return true;
            }

            // Refresh token must be invalid
            clearRefreshToken();
            clearToken();
            redirectToAuth();
        }
    }
    console.log('Unabled to refresh Token');
    return false;
};

const startRefreshTokenInterval = () => {
    console.log(`Starting Refresh token interval: ${AUTH_TOKEN_REFRESH_TIME / 1000}s`);
    if (tokenRefreshInterval) clearInterval(tokenRefreshInterval);

    tokenRefreshInterval = setInterval(() => {
        getNewToken();
    }, AUTH_TOKEN_REFRESH_TIME);
};

/**
 * Will run though the checks and processes required to load
 * a valid OAuth token
 */
export const handleInitialLoad = async () => {
    // First check localstorage for a token
    let token = getToken();
    let refreshToken = getRefreshToken();

    // Check if token is provided (won't come with a refresh token)
    // This will allow SRRA to integrate without logging in a 2nd time
    // no refresh token is a shame (TODO: find it in SRRA and also send it)?
    // But likely usage when embedded in SRRA is short term.
    const searchParams = new URLSearchParams(window.location.search);

    const tokenParam = searchParams.get('token');
    if (tokenParam) {
        // If we have a provided token, then
        // we just trust it, should be tested on
        // the external app side.
        setToken(tokenParam);
        return true;
    }

    // if it exists, test it.
    if (token) {
        try {
            const hasSelf = await getSelf();

            startRefreshTokenInterval();

            if (hasSelf) return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    if (refreshToken) {
        startRefreshTokenInterval();

        if (await getNewToken()) {
            return true;
        }
    }

    // If here then stored token doesn't exist or is bad
    // Kill it to be safe
    clearToken();

    // Check if token is provided externally
    // TODO: Although this step may not end up important enough

    // Check if a redirect had just occured giving us auth details
    const params = new URLSearchParams(window.location.search);

    console.log(params.toString());

    const code = params.get('code');
    const state = JSON.parse(params.get('state') ?? 'null');

    console.log(`Got code: ${code}`);

    if (code) {
        const tokenParams = new URLSearchParams({
            client_id: OAUTH.CLIENT_ID,
            grant_type: 'authorization_code',
            redirect_uri: OAUTH.REDIRECT_URI,
            code,
        });

        const response = await fetch(`${OAUTH.TOKEN_ENDPOINT}?${tokenParams.toString()}`);

        if (response.ok) {
            const data = await response.json();

            token = data.access_token;
            refreshToken = data.refresh_token;

            if (token && refreshToken && state) {
                setToken(token);
                setRefreshToken(refreshToken);

                startRefreshTokenInterval();

                const newUrl = `${window.location.protocol}//${window.location.host}${state?.path ?? '/'}`;

                window.history.replaceState({ path: newUrl }, '', newUrl);

                // return true; // TODO: Remove when CORS fixed

                selfCache = null;
                const hasSelf = await getSelf();

                // Return true if self
                return !!hasSelf;
            }
        }
    }

    // No redirect seems to have occured to perform redirect.
    redirectToAuth();

    await setTimeout(() => {}, 1000);

    return false;
};

export const handleInitialLoadImplicit = async () => {
    // First check localstorage for a token
    let token = getToken();

    // if it exists, test it.
    if (token) {
        // return true; // TODO: Remove when CORS fixed
        try {
            const hasSelf = await getSelf();
            if (hasSelf) return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    // If here then stored token doesn't exist or is bad
    // Kill it to be safe
    clearToken();

    // Check if token is provided externally
    // TODO: Although this step may not end up important enough

    // Check if a redirect had just occured giving us auth details
    const params = new URLSearchParams(window.location.hash.substring(1));

    token = params.get('access_token');
    const state = JSON.parse(params.get('state') ?? 'null');

    if (token && state) {
        setToken(token);

        const newUrl = `${window.location.protocol}//${window.location.host}${state?.path ?? '/'}`;

        window.history.replaceState({ path: newUrl }, '', newUrl);

        // return true; // TODO: Remove when CORS fixed

        selfCache = null;
        const hasSelf = await getSelf();

        // Return true if self
        return !!hasSelf;
    }

    // No redirect seems to have occured to perform redirect.
    redirectToAuth();

    await setTimeout(() => {}, 1000);

    return false;
};
