import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { JobManager } from 'models';

export interface JobState {
    jobs: JobManager.JobSummary[] | null;
    jobsState: 'init' | 'loading' | 'finished' | 'error';
    selectedJob: JobManager.Job | null;
    selectedJobState: 'init' | 'loading' | 'finished' | 'error';
}

const initialState: JobState = {
    jobs: null,
    jobsState: 'init',
    selectedJob: null,
    selectedJobState: 'init',
};

export const getJobs = createAsyncThunk('query/getJobs', async (skipCache?: boolean) => JobManager.getJobs(skipCache), {
    condition: (_, { getState }) => {
        const { jobs } = getState() as RootState;
        if (jobs.jobsState === 'loading') {
            // Already fetched or in progress, don't need to re-fetch
            return false;
        }
        return true;
    },
});

export const getJob = createAsyncThunk('query/getJob', async (id: string | number) => JobManager.getJob(id));

export const jobsSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        setJobs: (state, action: PayloadAction<JobState['jobs']>) => {
            state.jobs = action.payload;
        },
        setJobsState: (state, action: PayloadAction<JobState['jobsState']>) => {
            state.jobsState = action.payload;
        },
        setSelectedJob: (state, action: PayloadAction<JobState['selectedJob']>) => {
            state.selectedJob = action.payload;
        },
        setSelectedJobState: (state, action: PayloadAction<JobState['selectedJobState']>) => {
            state.selectedJobState = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getJobs.pending, (state) => {
            state.jobsState = 'loading';
        });

        builder.addCase(getJobs.rejected, (state) => {
            state.jobsState = 'error';
        });

        builder.addCase(getJobs.fulfilled, (state, action) => {
            state.jobsState = 'finished';
            state.jobs = action.payload;
        });

        builder.addCase(getJob.pending, (state) => {
            state.selectedJobState = 'loading';
        });

        builder.addCase(getJob.rejected, (state) => {
            state.selectedJobState = 'error';
        });

        builder.addCase(getJob.fulfilled, (state, action) => {
            state.selectedJobState = 'finished';
            state.selectedJob = action.payload;
        });
    },
});

export const jobActions = {
    getJob,
    getJobs,
    ...jobsSlice.actions,
};

export const selectQuery = (state: RootState) => state.fields;

export default jobsSlice.reducer;
