import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { RemoveCircleOutline } from '@material-ui/icons';

import { JobManager, QueryManager } from 'models';
import ListSetChanges from 'components/ListSetChanges';

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        root: {
            margin: `${0}px ${spacing(1)}px`,
            marginBottom: spacing(1),
            padding: spacing(0.5),
            backgroundColor: grey[100],
            borderRadius: 4,
        },
        pdfRoot: {
            margin: 0,
            padding: spacing(0.5),
            borderRadius: 4,
        },
        modificationHeader: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        icon: {
            fontSize: 24,
            cursor: 'pointer',
        },
        leftPadding: {
            paddingLeft: spacing(1),
        },
    })
);

interface Props {
    mod: JobManager.Modification;
    onRemove?: () => void;
    fields: Partial<Record<QueryManager.FieldIds, QueryManager.Field>> | null;
    isPDF?: boolean;
}

const ModificationDetails: React.FunctionComponent<Props> = ({ mod, onRemove, fields, isPDF }) => {
    const classes = useStyles();

    return (
        <div className={isPDF ? classes.pdfRoot : classes.root}>
            <div className={classes.modificationHeader}>
                <Typography variant="subtitle2">
                    <b>Where: </b>
                </Typography>
                {onRemove && <RemoveCircleOutline onClick={onRemove} className={classes.icon} />}
            </div>
            <Typography className={classes.leftPadding} variant="subtitle2">
                <b>
                    {QueryManager.fieldValuesToPrettyWhere(
                        mod.fieldValues,
                        fields,
                        'No attribute filters applied. Modifications will occur over all features in the original query.'
                    )}
                </b>
            </Typography>
            <Typography variant="subtitle2">
                <b>Set:</b>
            </Typography>
            {mod.set && <ListSetChanges set={mod.set} fields={fields} isPDF />}
        </div>
    );
};

export default ModificationDetails;
