import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
    Theme,
    Button,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Chip,
} from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import { Done, ExpandMore } from '@material-ui/icons';

import { useAppSelector } from 'hooks';
import { groups } from 'config/fields';
import { QueryManager } from 'models';
import Page from 'components/Page';
import FieldInput from 'components/FieldInput';

interface Props extends RouteComponentProps {
    onComplete?: (fieldValues: QueryManager.Query['fieldValues']) => void;
    onCancel?: () => void;
    defaultFieldValues?: QueryManager.Query['fieldValues'];
}

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        root: {},
        content: {
            padding: spacing(1),
        },
        accordionContent: {
            display: 'grid',
            gridGap: spacing(1),
        },
        chip: {
            marginLeft: spacing(1),
            backgroundColor: grey[200],
        },
        selectedChip: {
            marginLeft: spacing(1),
            backgroundColor: red[200],
        },
        summaryText: {
            margin: `${0}px ${spacing(1)}px`,
            marginBottom: spacing(1),
            padding: spacing(0.5),
            backgroundColor: grey[100],
            borderRadius: 4,
        },
        accordianSummaryFix: {
            display: 'flex',
            padding: `0px 16px`,
        },
    })
);

const QueryBuilderPage: React.FunctionComponent<Props> = ({ onComplete, onCancel, defaultFieldValues }) => {
    const classes = useStyles();
    const { fields } = useAppSelector((state) => state.fields);

    const fieldByGroup: Record<string, QueryManager.Field[]> = {};

    Object.keys(groups).forEach((group) => {
        const fieldsInGroup = groups[group as keyof typeof groups];

        fieldByGroup[group] = fieldsInGroup
            // Have to do type conversion gymnastics because it doesn't understand what the filter is after it
            .map((fieldId) => fields?.[fieldId as QueryManager.FieldIds] as QueryManager.Field)
            .filter((item) => !!item);
    });

    const [fieldValues, setFieldValues] = useState<NonNullable<QueryManager.Query['fieldValues']>>(
        defaultFieldValues ?? {}
    );
    const [filter, setFilter] = useState('');
    const [selectedGroup, setSelectedGroup] = useState<string>();

    return (
        <Page
            title="Query Builder"
            subtitle="Build a 'Where' clause using the fields below"
            onBackClick={() => onCancel && onCancel()}
            header={
                <TextField
                    variant="outlined"
                    label="Filter"
                    value={filter}
                    onChange={(event) => setFilter(event.target.value)}
                />
            }
            footer={
                <>
                    <Button variant="outlined" onClick={() => onComplete && onComplete(fieldValues)}>
                        <Done /> Submit
                    </Button>
                </>
            }
        >
            <div className={classes.content}>
                <Typography className={classes.summaryText} variant="subtitle2">
                    {QueryManager.fieldValuesToPrettyWhere(fieldValues, fields)}
                </Typography>
                {Object.keys(fieldByGroup).map((group) => {
                    const fieldsInGroup = fieldByGroup[group as keyof typeof fieldByGroup];

                    const filteredFieldsInGroup = fieldsInGroup.filter((field) => {
                        const filterUpper = filter.toUpperCase();
                        if (field.name.toUpperCase().indexOf(filterUpper) > -1) return true;
                        if (field.id.toUpperCase().indexOf(filterUpper) > -1) return true;

                        return false;
                    });

                    const selectedInGroupCount =
                        groups[group as keyof typeof fieldByGroup]?.filter((id) => !!fieldValues[id])?.length ?? 0;

                    return (
                        <Accordion
                            key={group}
                            expanded={group === selectedGroup}
                            onChange={(_, isExpanded) =>
                                isExpanded ? setSelectedGroup(group) : setSelectedGroup(undefined)
                            }
                            TransitionProps={{ unmountOnExit: true }}
                        >
                            <AccordionSummary className={classes.accordianSummaryFix} expandIcon={<ExpandMore />}>
                                <Typography variant="subtitle1">
                                    {group.replaceAll('-', ' ')}
                                    <Chip
                                        className={classes.chip}
                                        style={{
                                            fontWeight: filter && filteredFieldsInGroup.length > 0 ? 'bold' : undefined,
                                        }}
                                        label={`${filteredFieldsInGroup.length}/${fieldsInGroup.length} Fields`}
                                    />
                                    {selectedInGroupCount > 0 && (
                                        <Chip
                                            className={classes.selectedChip}
                                            label={`${selectedInGroupCount} selected`}
                                        />
                                    )}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionContent}>
                                {filteredFieldsInGroup.map((field) => (
                                    <div key={field.id}>
                                        <Typography>{field.name}</Typography>
                                        <FieldInput
                                            field={field}
                                            value={fieldValues[field.id]}
                                            allowMultiple
                                            onChange={(val) => setFieldValues({ ...fieldValues, [field.id]: val })}
                                        />
                                    </div>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
        </Page>
    );
};

export default QueryBuilderPage;
