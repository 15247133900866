import React, { useRef, useEffect, useState } from 'react';

import { Chart as ChartJS, ChartOptions, LinearScale, Title, Tooltip, SubTitle } from 'chart.js';
import { SankeyController, Flow } from 'chartjs-chart-sankey';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Theme } from '@material-ui/core';

// Init ChartJS
ChartJS.register(Title, Tooltip, SubTitle, LinearScale, SankeyController, Flow);

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        root: {
            padding: spacing(1),
        },
    })
);

interface Props {
    options: ChartOptions;
    style?: React.CSSProperties;
    className?: string;
    getRef?: (ref: React.RefObject<HTMLCanvasElement>) => void;
}

const Chart: React.FunctionComponent<Props> = ({ style, className, options, getRef }) => {
    const classes = useStyles();

    const ctx = useRef<HTMLCanvasElement>(null);
    const [chart, setChart] = useState<ChartJS>();

    useEffect(() => {
        if (getRef) getRef(ctx);
    }, [ctx]);

    useEffect(() => {
        if (chart) {
            chart.options = options;
            chart.update();
        }
    }, [options]);

    useEffect(() => {
        if (ctx.current) {
            if (chart) {
                chart.destroy();
            }

            setChart(new ChartJS(ctx.current, options));
        }
    }, [ctx]);

    return <canvas ref={ctx} style={style} className={`${classes.root} ${className ?? ''}`} />;
};

export default Chart;
