/**
 * Acts as the configuration root of the application where any
 * setup occurs but none of the business logic.
 */

import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import './index.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import createTheme from 'theme';
import store from 'store';

import App from 'App';

import { AuthManager } from 'models';

import reportWebVitals from './reportWebVitals';

AuthManager.handleInitialLoad().then((success) => {
    if (success) {
        // User authenticated, start loading app
        ReactDOM.render(
            <Provider store={store}>
                <ThemeProvider theme={createTheme({})}>
                    <Router>
                        <App />
                    </Router>
                </ThemeProvider>
            </Provider>,
            document.getElementById('root')
        );
    } else {
        // Failed to authenticate, show error
        ReactDOM.render(
            <Provider store={store}>
                <ThemeProvider theme={createTheme({})}>
                    <Router>
                        <div style={{ display: 'grid', width: '100%', placeItems: 'center', padding: 24 }}>
                            <h4>Failed to Authenticate with Portal!</h4>
                        </div>
                    </Router>
                </ThemeProvider>
            </Provider>,
            document.getElementById('root')
        );
    }
});

// Will display a spinner until handleInitialLoad finishes
ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={createTheme({})}>
            <Router>
                <div style={{ display: 'grid', width: '100%', placeItems: 'center', padding: 24 }}>
                    <CircularProgress size={24} />
                </div>
            </Router>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
