import React, { useState } from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { grey } from '@material-ui/core/colors';

import { JobManager, QueryManager, CalculatorManager } from 'models';
import { useAppSelector } from 'hooks';

import Page from 'components/Page';
import ListSetChanges from 'components/ListSetChanges';
import FieldInput from 'components/FieldInput';
import QueryBuilderPage from 'pages/QueryBuilder';

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        root: {
            display: 'grid',
            gridTemplateRows: 'auto',
        },
        input: {
            margin: spacing(1),
        },
        button: {
            padding: spacing(1),
        },
        buttonContainer: {
            padding: spacing(1),
            display: 'grid',
            alignItems: 'end',
        },
        setContainer: {
            padding: `${spacing(1)}px 0px`,
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: 'auto auto',
            gridAutoFlow: 'column',
            gap: `${spacing(0.25)}px ${spacing(1)}px`,
        },
        descriptionText: {
            paddingLeft: spacing(2),
        },
        container: {
            padding: spacing(1),
        },
        summaryText: {
            margin: `${0}px ${spacing(1)}px`,
            marginBottom: spacing(1),
            padding: spacing(0.5),
            backgroundColor: grey[100],
            borderRadius: 4,
        },
    })
);

interface Props extends RouteComponentProps<{ calc?: string }> {
    onAddModification: (modification: JobManager.Modification) => void;
    job: JobManager.NewJob;
}

const JobNewModificationAddPage: React.FunctionComponent<Props> = ({
    onAddModification,
    job,
    history,
    location,
    match,
}) => {
    const classes = useStyles();
    const { fields } = useAppSelector((state) => state.fields);

    const [qbOpen, setQBOpen] = useState(false);

    const [fieldValues, setFieldValues] = useState<JobManager.Modification['fieldValues']>({});

    const where = fieldValues ? QueryManager.fieldValuesToWhere(fieldValues, fields) : undefined;

    const [set, setSet] = useState<JobManager.Modification['set']>();

    const [newSetField, setNewSetField] = useState<QueryManager.Field>();
    const [newSetFrom, setNewSetFrom] = useState<string[]>();
    const [newSetTo, setNewSetTo] = useState<string>();

    const calculator = CalculatorManager.getById(job.type ?? '');

    const setFields = CalculatorManager.getSetFields(calculator, fields);

    const submitModification = () => {
        if (fieldValues && set) {
            onAddModification({ fieldValues, set, where: where ?? 'true' });
            history.push(`/new/${job.type}/modifications`);
        }
    };

    if (qbOpen)
        return (
            <QueryBuilderPage
                defaultFieldValues={fieldValues}
                onComplete={(newFieldValues) => {
                    setFieldValues(newFieldValues ?? {});
                    setQBOpen(false);
                }}
                onCancel={() => setQBOpen(false)}
                history={history}
                location={location}
                match={match}
            />
        );

    return (
        <Page
            title="Select Features"
            subtitle="Select a Criteria for the initial dataset"
            backUrl={`/new/${job.type}/modifications`}
            footer={
                <Button
                    className={classes.button}
                    disabled={where == null || set == null}
                    variant="contained"
                    onClick={submitModification}
                >
                    Add Moficiation
                </Button>
            }
        >
            <div className={classes.root}>
                <div className={classes.container}>
                    <Typography variant="subtitle1">
                        <b>Where</b>
                    </Typography>
                    <Typography className={classes.summaryText} variant="subtitle2">
                        {QueryManager.fieldValuesToPrettyWhere(fieldValues ?? {}, fields)}
                    </Typography>
                </div>
                <div className={classes.buttonContainer}>
                    <Button className={classes.button} variant="outlined" onClick={() => setQBOpen(true)}>
                        Open Query Builder
                    </Button>
                </div>
                <div className={classes.container}>
                    <Typography variant="subtitle1">
                        <b>Set</b>
                    </Typography>
                    {set && (
                        <ListSetChanges
                            set={set}
                            fields={fields}
                            onRemoveSet={(id, index) => {
                                const setListCopy = [...(set[id] ?? [])];
                                setListCopy.splice(index, 1);

                                if (setListCopy.length > 0) {
                                    setSet({ ...set, [id]: [...setListCopy] });
                                } else {
                                    const setCopy = { ...set };
                                    delete setCopy[id];

                                    if (Object.keys(setCopy).length > 0) {
                                        setSet(setCopy);
                                    } else {
                                        // Setting to undefined will disable 'add modification' button
                                        setSet(undefined);
                                    }
                                }
                            }}
                        />
                    )}
                </div>
                <div className={classes.container}>
                    <Autocomplete<QueryManager.Field>
                        onChange={(_, value) => {
                            setNewSetField(value ?? undefined);
                            setNewSetFrom(undefined);
                            setNewSetTo(undefined);
                        }}
                        value={newSetField ?? null}
                        getOptionLabel={(o) => o.name}
                        options={setFields ?? []}
                        getOptionSelected={(opt, val) => opt.id === val.id}
                        renderInput={(params) => <TextField {...params} label="New Update Field" variant="outlined" />}
                    />
                    <div className={classes.setContainer}>
                        <FieldInput
                            field={newSetField}
                            values={newSetFrom ?? []}
                            title="From"
                            allowMultiple
                            onChange={(_, values) => setNewSetFrom(values)}
                        />
                        <FieldInput
                            field={newSetField}
                            value={newSetTo ?? ''}
                            title="To"
                            onChange={(value) => setNewSetTo(value)}
                        />
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        onClick={() => {
                            if (newSetField != null && newSetFrom != null && newSetTo != null) {
                                setSet({
                                    ...set,
                                    [newSetField.id]: [
                                        ...(set?.[newSetField.id] ?? []),
                                        ...newSetFrom.map((setFrom) => {
                                            const fromSetFieldValues = { [newSetField.id]: setFrom };
                                            return [
                                                fromSetFieldValues,
                                                QueryManager.fieldValuesToWhere(fromSetFieldValues, fields),
                                                newSetTo,
                                            ];
                                        }),
                                    ],
                                });
                            }
                        }}
                        disabled={!(newSetField != null && newSetFrom != null && newSetTo != null)}
                    >
                        Add Field Update
                    </Button>
                </div>
            </div>
        </Page>
    );
};

export default JobNewModificationAddPage;
