import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, CircularProgress, Button } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        root: {
            display: 'grid',
            gridTemplateRows: 'auto auto auto 1fr auto',
            height: '100%',
        },
        title: {
            display: 'grid',
            placeItems: 'center',
            padding: spacing(1),
            gridTemplateColumns: '1fr 6fr 1fr',
        },
        subtitle: {
            display: 'grid',
            placeItems: 'center',
            padding: spacing(1),
        },
        back: {
            marginRight: 'auto',
            padding: `${spacing(0.5)}px ${spacing(1)}px`,
        },
        header: {
            display: 'grid',
            padding: spacing(1),
        },
        scrollable: {
            overflow: 'auto',
        },
        footer: {
            display: 'grid',
            alignItems: 'end',
            padding: spacing(1),
        },
        loading: {
            display: 'grid',
            placeItems: 'center',
            padding: spacing(2),
        },
        refresh: {},
    })
);

export interface PageProps {
    title: string;
    subtitle?: string;
    backUrl?: string;
    onBackClick?: () => void;
    boldSubtitle?: boolean;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    loading?: boolean;
    onRefresh?: () => void;
    refreshing?: boolean;
}

const Page: React.FunctionComponent<PageProps> = ({
    title,
    subtitle,
    loading,
    header,
    footer,
    children,
    backUrl,
    onBackClick,
    boldSubtitle,
    onRefresh,
    refreshing,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                {backUrl ? (
                    <Button className={classes.back} variant="text" component={RouterLink} to={backUrl}>
                        Back
                    </Button>
                ) : (
                    <>
                        {onBackClick ? (
                            <Button className={classes.back} variant="text" onClick={onBackClick}>
                                Back
                            </Button>
                        ) : (
                            <div />
                        )}
                    </>
                )}
                <Typography variant="h5">{title}</Typography>
                {onRefresh && (
                    <Button className={classes.refresh} onClick={onRefresh} disabled={refreshing}>
                        {refreshing ? <CircularProgress size={20} style={{ padding: 2 }} /> : <Refresh />}
                    </Button>
                )}
            </div>
            <div className={classes.subtitle}>
                <Typography variant="subtitle1" style={{ fontWeight: boldSubtitle ? 'bold' : undefined }}>
                    {subtitle ?? ''}
                </Typography>
            </div>
            <div className={classes.header}>{header}</div>
            {loading && (
                <div className={classes.loading}>
                    <CircularProgress size={32} />
                </div>
            )}
            {!loading && <div className={classes.scrollable}>{children}</div>}
            <div className={classes.footer}>{footer}</div>
        </div>
    );
};

export default Page;
