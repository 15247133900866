import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JobManager } from 'models';
import type { RootState } from 'store';

export interface ConfigState {
    filter: string;
    sort: [keyof JobManager.JobSummary, boolean];
}

const initialState: ConfigState = {
    filter: '',
    sort: ['submitted', true],
};

export const configSlice = createSlice({
    name: 'configs',
    initialState,
    reducers: {
        setFilter: (state, action: PayloadAction<ConfigState['filter']>) => {
            state.filter = action.payload;
        },
        setSort: (state, action: PayloadAction<ConfigState['sort']>) => {
            state.sort = action.payload;
        },
    },
});

export const configActions = {
    ...configSlice.actions,
};

export const selectConfig = (state: RootState) => state.config;

export default configSlice.reducer;
