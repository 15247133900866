import React, { useEffect } from 'react';

import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, TextField, Select, MenuItem, Button } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Add } from '@material-ui/icons';

import { useAppDispatch, useAppSelector } from 'hooks';
import { jobActions } from 'slices/job';
import { configActions } from 'slices/config';
import { JobManager } from 'models';
import { JOB_UPDATE_FREQUENCY } from 'config';

import Page from 'components/Page';
import JobListItem from 'components/JobListItem';

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        selectInput: {
            minWidth: 180,
        },
        filters: {
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gap: spacing(2),
        },
    })
);

const sortableFields: [keyof JobManager.Job, boolean, string][] = [
    ['completed', true, 'Completed'],
    ['submitted', true, 'Oldest'],
    ['submitted', false, 'Newest'],
    ['status', false, 'Status'],
];

const JobSummaryPage: React.FunctionComponent<RouteComponentProps<{ id?: string }>> = ({ history, match }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const { jobs, jobsState } = useAppSelector((state) => state.jobs);
    const { filter, sort } = useAppSelector((state) => state.config);
    const [sortField, ascending] = sort;

    const filteredJobs =
        jobs
            ?.filter((job) => {
                if (!filter) return true;
                const filterUpper = filter.toUpperCase();
                if (job.name.toUpperCase().indexOf(filterUpper) > -1) return true;
                if (job.description.toUpperCase().indexOf(filterUpper) > -1) return true;

                return false;
            })
            ?.sort((a, b) => {
                if (!sortField) return a.id < b.id ? -1 : 1;

                // Note sorting by 'status' is done alphabetically
                // This happens to work right
                // but if the states are changed, then this should
                // sort by a priority list of status'

                const fieldA = a[sortField];
                const fieldB = b[sortField];

                if (!fieldA && !fieldB) return 0;
                if (!fieldA) return 1;
                if (!fieldB) return -1;

                if (ascending) return fieldA < fieldB ? -1 : 1;

                return fieldA < fieldB ? 1 : -1;
            }) ?? [];

    useEffect(() => {
        let timeoutRef: NodeJS.Timeout | null = null;

        if (jobsState === 'init') {
            // Don't wait for the timeout below
            dispatch(jobActions.getJobs());
        }

        if (jobsState !== 'loading') {
            // When init or finished loading (or error) then trigger
            // waiting for the next update
            timeoutRef = setTimeout(() => dispatch(jobActions.getJobs()), JOB_UPDATE_FREQUENCY);
        }

        return () => {
            if (timeoutRef) clearTimeout(timeoutRef);
        };
    }, [jobsState]);

    return (
        <Page
            title="Job Summary"
            subtitle="Select a Job to view or create a new Job"
            loading={!jobs}
            onRefresh={() => {
                dispatch(jobActions.getJobs(true));
            }}
            refreshing={jobsState === 'loading'}
            header={
                <div className={classes.filters}>
                    <TextField
                        variant="outlined"
                        label="Filter"
                        value={filter}
                        onChange={(event) => dispatch(configActions.setFilter(event.target.value))}
                    />
                    <Select
                        label="Sort By"
                        variant="outlined"
                        value={`${sortField}--${ascending}`}
                        onChange={(event) => {
                            const [field, asc] = ((event.target.value as string) ?? '').split('--');
                            dispatch(configActions.setSort([field as keyof JobManager.JobSummary, asc === 'true']));
                        }}
                        className={classes.selectInput}
                    >
                        {sortableFields.map(([field, asc, label]) => (
                            <MenuItem key={`${field}--${asc}`} value={`${field}--${asc}`}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            }
            footer={
                <>
                    <Button variant="outlined" component={RouterLink} to="/new">
                        <Add /> New Job
                    </Button>
                </>
            }
        >
            {filteredJobs.map((job) => (
                <JobListItem
                    key={job.id}
                    job={job}
                    onClick={() => {
                        history.push(`/job/${job.id}`);
                    }}
                    style={{ backgroundColor: `${job.id}` === match.params?.id ? grey[200] : undefined }}
                />
            ))}
        </Page>
    );
};

export default JobSummaryPage;
