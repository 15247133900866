/**
 * @author hlovdal & SgtPooki (Stackoverflow)
 * See: https://stackoverflow.com/questions/46583883/typescript-pick-properties-with-a-defined-type
 *
 * Returns an interface stripped of all keys that don't resolve to U, defaulting
 * to a non-strict comparison of T[key] extends U. Setting B to true performs
 * a strict type comparison of T[key] extends U & U extends T[key]
 */
export type KeysOfType<T, U, B = false> = {
    [P in keyof T]: B extends true
        ? T[P] extends U
            ? U extends T[P]
                ? P
                : never
            : never
        : T[P] extends U
        ? P
        : never;
}[keyof T];

export const getTimeTextFromMillis = (ms: number, suffix = ' ago'): string => {
    const seconds = Math.floor(ms / 1000);
    if (seconds < 10) return `Less than 10 seconds${suffix}`;

    if (seconds < 60) return `${seconds} seconds${suffix}`;
    const minutes = Math.floor(ms / 60000);

    if (minutes < 120) return `${minutes} minutes${suffix}`;

    const hours = Math.floor(ms / 3600000);
    if (hours < 24) return `${hours} hours${suffix}`;

    const days = Math.floor(ms / 86400000);
    return `${days} days${suffix}`;
};

export const makeIdPretty = (id: string) =>
    id
        .toLowerCase()
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

export const round = (num: number, dp: number) => {
    const mul = 10 ** dp;
    return Math.round((num + Number.EPSILON) * mul) / mul;
};

export const getTimeAgoString = (date: Date | number): string =>
    getTimeTextFromMillis(Date.now() - (typeof date === 'number' ? date : date.getTime()));

export const sleep = async (delay: number, getTimeout: (timeout: NodeJS.Timeout) => void = () => {}) =>
    new Promise<void>((resolve) => getTimeout(setTimeout(() => resolve(), delay)));

export const downloadObjectAsJSON = (obj: any, name?: string) => {
    const link = window.document.createElement('a');
    const content = encodeURIComponent(JSON.stringify(obj));
    link.setAttribute('href', `data:application/json;charset=utf-8,${content}`);
    link.setAttribute('download', `${name ?? obj.name ?? 'download'}.json`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const minimator = (x: number, y: number, z: number) => {
    if (x <= y && x <= z) return x;
    if (y <= x && y <= z) return y;
    return z;
};

export const levenshteinDistance = (a: string, b: string) => {
    const flip = a.length < b.length;

    const m = flip ? b : a;
    const n = flip ? a : b;

    const r: number[][] = [[...new Array(n.length + 1)].map((_, i) => i)];

    [...new Array(m.length)]
        .map((_, x) => x + 1)
        .forEach((i) => {
            r[i] = [i];

            [...new Array(n.length)]
                .map((_, x) => x + 1)
                .forEach((j) => {
                    const cost = m[i - 1] === n[j - 1] ? 0 : 1;
                    r[i][j] = minimator(r[i - 1][j] + 1, r[i][j - 1] + 1, r[i - 1][j - 1] + cost);
                });
        });

    return r[m.length][n.length];
};

/**
 * @summary Will scan a string starting at an opening token and locating the corresponding closing token.
 * @param openIndex The character index of the string provided as the next parameter which should contain the specified opening token.
 * @param str The string to be scanned
 * @param open_token The character that represents the open token, defaults to a '('
 * @param close_token The character that represents the closing token, defaults to a ')'
 * @returns The index of the corresponding closing token
 */
export const findCloseBracket = (
    openIndex: number,
    str: string,
    open_token: string = '(',
    close_token: string = ')'
) => {
    // Given an index and a string, will return the matching closing bracket
    let counter = 0;
    for (let i = openIndex; i < str.length; i += 1) {
        if (str[i] === open_token) counter += 1;
        if (str[i] === close_token) counter -= 1;
        if (counter === 0) return i;
    }
    return null;
};
