import React, { useState, useEffect } from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Button, Typography } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp, Close } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

import { useAppSelector } from 'hooks';
import { JobManager, QueryManager } from 'models';

import Page from 'components/Page';
import QueryBuilderPage from 'pages/QueryBuilder';

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        root: {
            display: 'grid',
            gridTemplateRows: 'auto',
        },
        input: {
            margin: spacing(1),
        },
        button: {
            padding: spacing(1),
        },
        buttonContainer: {
            padding: spacing(1),
            display: 'grid',
            alignItems: 'end',
        },
        twoColumn: {
            padding: spacing(1),
            display: 'grid',
            gridTemplateColumns: '1fr auto auto ',
        },
        geometryTextContainer: {
            width: '100%',
        },
        summaryText: {
            margin: `${0}px ${spacing(1)}px`,
            marginBottom: spacing(1),
            padding: spacing(0.5),
            backgroundColor: grey[100],
            borderRadius: 4,
        },
        generalText: {
            margin: `${0}px ${spacing(1)}px`,
            marginBottom: spacing(1),
            padding: spacing(0.5),
        },
    })
);

interface Props extends RouteComponentProps<{ calc?: string }> {
    onSectionUpdate: (query: Partial<QueryManager.Query>) => void;
    onNextSection: () => void;
    job: JobManager.NewJob;
}

const JobNewDetails: React.FunctionComponent<Props> = ({
    onSectionUpdate,
    onNextSection,
    job,
    history,
    location,
    match,
}) => {
    const classes = useStyles();
    const { fields } = useAppSelector((state) => state.fields);

    const [geometryOpen, setGeometryOpen] = useState(false);
    const [qbOpen, setQBOpen] = useState(false);
    const [fieldValues, setFieldValues] = useState<JobManager.Modification['fieldValues']>(
        job.query?.fieldValues ?? {}
    );

    useEffect(() => {
        onSectionUpdate({ fieldValues });
    }, [fieldValues]);

    useEffect(() => {
        if (job.query?.fieldValues) setFieldValues(job.query.fieldValues);
    }, [job]);

    if (qbOpen)
        return (
            <QueryBuilderPage
                defaultFieldValues={fieldValues}
                onComplete={(newFieldValues) => {
                    setFieldValues(newFieldValues ?? {});
                    setQBOpen(false);
                }}
                onCancel={() => setQBOpen(false)}
                history={history}
                location={location}
                match={match}
            />
        );

    return (
        <Page
            title="Select Features"
            subtitle="Select a Criteria for the initial dataset"
            footer={
                <Button className={classes.button} variant="contained" onClick={onNextSection}>
                    Save &amp; Next
                </Button>
            }
        >
            <div className={classes.root}>
                <div className={classes.buttonContainer}>
                    <Typography className={classes.summaryText} variant="subtitle2">
                        {QueryManager.fieldValuesToPrettyWhere(fieldValues ?? {}, fields)}
                    </Typography>
                    <Button className={classes.button} variant="outlined" onClick={() => setQBOpen(true)}>
                        Set using Query Builder
                    </Button>
                </div>
                {job.query?.geometry == null && (
                    <div className={classes.buttonContainer}>
                        <Typography className={classes.generalText} variant="subtitle1">
                            No Imported Geometry, no spatial filter will be applied. The enitre state will be included.
                        </Typography>
                    </div>
                )}
                {job.query?.geometry && job.query?.geometryType && (
                    <div className={classes.buttonContainer}>
                        <div className={classes.twoColumn}>
                            <Typography variant="subtitle1">Imported Geometry data will also be used</Typography>
                            {geometryOpen && (
                                <Button onClick={() => setGeometryOpen(false)}>
                                    <KeyboardArrowUp />
                                </Button>
                            )}
                            {!geometryOpen && (
                                <Button onClick={() => setGeometryOpen(true)}>
                                    <KeyboardArrowDown />
                                </Button>
                            )}
                            <Button onClick={() => onSectionUpdate({ geometry: undefined, geometryType: undefined })}>
                                <Close />
                            </Button>
                        </div>
                        {geometryOpen && (
                            <div className={classes.geometryTextContainer}>
                                <Typography
                                    className={classes.summaryText}
                                    variant="subtitle2"
                                    style={{
                                        overflowX: 'auto',
                                    }}
                                >
                                    <pre>
                                        {job.query?.geometryType}
                                        {'\n'}
                                        {JSON.stringify(JSON.parse(job.query?.geometry), null, 2)}
                                    </pre>
                                </Typography>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Page>
    );
};

export default JobNewDetails;
