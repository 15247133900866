import React, { useEffect, useState } from 'react';

import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Typography, Button } from '@material-ui/core';
import {
    CheckCircleOutline,
    AccessTime,
    ErrorOutline,
    Timelapse,
    Loop,
    Delete,
    SaveAlt,
    Done,
    Replay,
    Stop,
    KeyboardArrowDown,
    KeyboardArrowUp,
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

import Page from 'components/Page';
import ModificationDetails from 'components/ModificationDetails';

import { useAppSelector, useAppDispatch } from 'hooks';
import { jobActions } from 'slices/job';
import { JOB_UPDATE_FREQUENCY } from 'config';
import { JobManager, QueryManager, CalculatorManager } from 'models';
import { getTimeTextFromMillis, downloadObjectAsJSON, round } from 'utils';
import ConfirmDialog from 'components/ConfirmDialog';

const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        overview: {
            borderRadius: spacing(0.5),
        },
        overviewRow: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: `${spacing(0.5)}px ${spacing(1)}px`,
            backgroundColor: grey[300],
            '&:nth-of-type(odd)': {
                backgroundColor: grey[100],
            },
        },
        inlineTextIcon: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            fontSize: 20,
            paddingRight: spacing(1),
        },
        descriptions: {
            padding: spacing(1),
        },
        descriptionText: {
            paddingLeft: spacing(2),
        },
        modifications: {
            padding: spacing(1),
        },
        actions: {
            display: 'grid',
            gridAutoFlow: 'column',
            gap: spacing(2),
        },
        buttonContainer: {
            padding: spacing(1),
            display: 'grid',
            alignItems: 'end',
        },
        twoColumn: {
            padding: spacing(1),
            display: 'grid',
            gridTemplateColumns: '1fr auto',
        },
        geometryTextContainer: {
            width: '100%',
        },
        summaryText: {
            margin: `${0}px ${spacing(1)}px`,
            marginBottom: spacing(1),
            padding: spacing(0.5),
            marginLeft: spacing(2),
            backgroundColor: grey[100],
            borderRadius: 4,
        },
    })
);

const JobDetailsPage: React.FunctionComponent<RouteComponentProps<{ id: string }>> = ({ match, history }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const { fields } = useAppSelector((state) => state.fields);
    const { selectedJob: job, selectedJobState: jobState } = useAppSelector((state) => state.jobs);

    const [geometryOpen, setGeometryOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const color = job && job.status ? theme.palette.status[job.status] : '#000000';

    const id = +match.params?.id;

    let error = '';

    if (job?.error) {
        try {
            if (typeof job.error === 'string') {
                error = JSON.stringify(JSON.parse(job.error), null, 2);
            } else {
                error = JSON.stringify(job.error, null, 2);
            }
        } catch (e) {
            error = job.error;
        }
    }

    const getIcon = (): React.ReactNode => {
        switch (job?.status) {
            case 'Ready':
                return <CheckCircleOutline style={{ color }} className={classes.icon} />;
            case 'Error':
                return <ErrorOutline style={{ color }} className={classes.icon} />;
            case 'Pending':
                return <AccessTime style={{ color }} className={classes.icon} />;
            case 'Processing':
                return <Timelapse style={{ color }} className={classes.icon} />;
            default:
                return <Loop style={{ color }} className={classes.icon} />;
        }
    };

    const cancelJob = () => {
        if (job?.id != null) {
            JobManager.cancelJob(job.id).then(() => {
                dispatch(jobActions.getJobs(true));
                setLoading(false);
            });
            setLoading(true);
        }
    };

    const deleteJob = () => {
        if (job?.id != null) {
            JobManager.deleteJob(job.id).then(() => {
                setLoading(false);
                dispatch(jobActions.getJobs(true));
                history.push('/');
            });
            setLoading(true);
        }
    };

    const reRunJob = (noDelete?: boolean) => {
        if (job?.id != null) {
            JobManager.addJob(job).then((newJob) => {
                if (!newJob) {
                    setLoading(false);
                    console.error('Unable to rerun job. An error occured when adding it.');
                } else if (!noDelete) {
                    // Delete old job (comment out if we want to keep old error job)
                    JobManager.deleteJob(job.id).then(() => {
                        setLoading(false);
                        dispatch(jobActions.getJobs(true));
                        history.push(`/job/${newJob.id}`);
                    });
                } else {
                    setLoading(false);
                    dispatch(jobActions.getJobs(true));
                    history.push(`/job/${newJob.id}`);
                }
            });
            setLoading(true);
        }
    };

    useEffect(() => {
        if (id !== null) {
            dispatch(jobActions.getJob(id));
        }
    }, [match.params?.id]);

    useEffect(() => {
        let timeoutRef: NodeJS.Timeout | null = null;
        if (jobState === 'finished' && (job?.status === 'Pending' || job?.status === 'Processing')) {
            timeoutRef = setTimeout(() => {
                dispatch(jobActions.getJob(id));
            }, JOB_UPDATE_FREQUENCY);
        }

        if (jobState === 'finished' && (job?.status === 'Ready' || job?.status === 'Error')) {
            dispatch(jobActions.getJobs(true));
        }

        return () => {
            if (timeoutRef) clearTimeout(timeoutRef);
        };
    }, [job?.status, jobState]);

    return (
        <Page
            title="Job Details"
            subtitle={job?.name}
            loading={loading || (!job && jobState === 'loading')}
            onRefresh={() => {
                dispatch(jobActions.getJob(id));
            }}
            refreshing={jobState === 'loading'}
            footer={
                <div className={classes.actions}>
                    <Button variant="outlined" onClick={() => setConfirmDeleteOpen(true)}>
                        <Delete /> Delete
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            if (job) {
                                const { user, processArn, csv, ...rest } = job;
                                downloadObjectAsJSON(rest);
                            }
                        }}
                    >
                        <SaveAlt /> Save Query
                    </Button>
                    {job?.status === 'Ready' && (
                        <Button variant="outlined" component={RouterLink} to={`/job/${match.params?.id}/results`}>
                            <Done /> View Results
                        </Button>
                    )}
                    {job?.status === 'Error' && (
                        <Button variant="outlined" onClick={() => reRunJob()}>
                            <Replay /> Re-run
                        </Button>
                    )}
                    {job?.status !== 'Ready' && job?.status !== 'Error' && (
                        <Button variant="outlined" onClick={cancelJob}>
                            <Stop /> Cancel
                        </Button>
                    )}
                </div>
            }
            header={
                <>
                    {!job && !(loading || jobState === 'loading' || jobState === 'init') && (
                        <Typography variant="subtitle1">
                            <b>Job Not Found</b>
                        </Typography>
                    )}
                    {!job && !loading && jobState === 'error' && (
                        <Typography variant="subtitle1">
                            <b>Error Loading Job</b>
                        </Typography>
                    )}
                    {job && (
                        <div className={classes.overview}>
                            <div className={classes.overviewRow}>
                                <Typography variant="subtitle1">
                                    <b>Status</b>
                                </Typography>
                                <Typography variant="subtitle1" className={classes.inlineTextIcon} style={{ color }}>
                                    {getIcon()}
                                    <b>{job.status}</b>
                                </Typography>
                            </div>
                            <div className={classes.overviewRow}>
                                <Typography variant="subtitle1">
                                    <b>Type</b>
                                </Typography>
                                <Typography variant="subtitle1">
                                    {CalculatorManager.getById(job.type)?.name ?? 'N/A'}
                                </Typography>
                            </div>
                            <div className={classes.overviewRow}>
                                <Typography variant="subtitle1">
                                    <b>Execution Time</b>
                                </Typography>
                                <Typography variant="subtitle1">
                                    {job.results?.duration ? getTimeTextFromMillis(job.results.duration, '') : 'N/A'}
                                </Typography>
                            </div>
                            <div className={classes.overviewRow}>
                                <Typography variant="subtitle1">
                                    <b>Selected Features</b>
                                </Typography>
                                <Typography variant="subtitle1">{job.results?.count ?? 'N/A'}</Typography>
                            </div>
                            <div className={classes.overviewRow}>
                                <Typography variant="subtitle1">
                                    <b>Length of Road</b>
                                </Typography>
                                <Typography variant="subtitle1">
                                    {job.results?.length ? `${round(job.results.length, 3)} km` : 'N/A'}
                                </Typography>
                            </div>
                        </div>
                    )}
                </>
            }
        >
            {job && (
                <>
                    <div className={classes.descriptions}>
                        <Typography variant="subtitle1">
                            <b>Description</b>
                        </Typography>
                        <Typography variant="subtitle2" className={classes.descriptionText}>
                            {job.description}
                        </Typography>
                    </div>
                    <div className={classes.descriptions}>
                        <Typography variant="subtitle1">
                            <b>Selection Criteria</b>
                        </Typography>
                        <Typography variant="subtitle2" className={classes.summaryText}>
                            {QueryManager.fieldValuesToPrettyWhere(job.query?.fieldValues ?? {}, fields)}
                        </Typography>
                        {job.query?.geometry && job.query?.geometryType && (
                            <div className={classes.buttonContainer}>
                                <div className={classes.twoColumn}>
                                    <Typography variant="subtitle1">
                                        A Geometry also contributed to this selection.
                                    </Typography>
                                    {geometryOpen && (
                                        <Button onClick={() => setGeometryOpen(false)}>
                                            <KeyboardArrowUp />
                                        </Button>
                                    )}
                                    {!geometryOpen && (
                                        <Button onClick={() => setGeometryOpen(true)}>
                                            <KeyboardArrowDown />
                                        </Button>
                                    )}
                                </div>
                                {geometryOpen && (
                                    <div className={classes.geometryTextContainer}>
                                        <Typography
                                            className={classes.summaryText}
                                            variant="subtitle2"
                                            style={{
                                                overflowX: 'auto',
                                            }}
                                        >
                                            <pre>
                                                {job.query?.geometryType}
                                                {'\n'}
                                                {JSON.stringify(JSON.parse(job.query?.geometry), null, 2)}
                                            </pre>
                                        </Typography>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {error && (
                        <div className={classes.descriptions}>
                            <Typography variant="subtitle1">
                                <b>Error Details</b>
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className={classes.summaryText}
                                style={{
                                    overflowX: 'auto',
                                }}
                            >
                                <pre>{error}</pre>
                            </Typography>
                        </div>
                    )}
                    <div className={classes.modifications}>
                        <Typography variant="h6" align="center">
                            Data Modifications
                        </Typography>
                        {job.modifications?.map((mod) => (
                            <ModificationDetails key={`${mod.where}`} mod={mod} fields={fields} />
                        ))}
                        {job.modifications.length === 0 && (
                            <div>
                                <Typography variant="subtitle1">
                                    <b>No Modifications Performed</b>
                                </Typography>
                            </div>
                        )}
                    </div>
                </>
            )}
            <ConfirmDialog
                open={confirmDeleteOpen}
                onClose={() => setConfirmDeleteOpen(false)}
                message="Are you sure you want to delete this Job?"
                onConfirm={() => {
                    deleteJob();
                }}
            />
        </Page>
    );
};

export default JobDetailsPage;
