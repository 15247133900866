import React, { useRef } from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { KeyboardArrowRight, Publish } from '@material-ui/icons';

import Page from 'components/Page';
import CalculatorListItem from 'components/CalculatorListItem';
import ListItem from 'components/ListItem';

import { CalculatorManager, JobManager } from 'models';

const useStyles = makeStyles(() =>
    createStyles({
        icon: {
            fontSize: 32,
        },
    })
);

interface Props extends RouteComponentProps {
    onSelectCalculator: (calculator: CalculatorManager.Calculator) => void;
    onUploadJob: (job: JobManager.Job) => void;
}

const JobNewSelectPage: React.FunctionComponent<Props> = ({ onSelectCalculator, onUploadJob }) => {
    const classes = useStyles();
    const inputFile = useRef<HTMLInputElement>(null);

    const loadFileClick = async () => {
        inputFile.current?.click();
    };

    const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target?.files?.[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target?.result) {
                    try {
                        const uploadedJob = JSON.parse(e.target.result as string) as JobManager.Job;
                        onUploadJob(uploadedJob);
                    } catch (err) {
                        console.error('Unable to parse user uploaded file');
                        console.error(err);
                    }
                }
            };

            reader.readAsText(file);
        }
    };

    return (
        <Page title="New Job" subtitle="Select a Calculation to create">
            {CalculatorManager.calculators
                .filter((x) => !x.disabled)
                .map((calculator) => (
                    <CalculatorListItem
                        key={calculator.id}
                        calculator={calculator}
                        onClick={() => onSelectCalculator(calculator)}
                    />
                ))}
            <ListItem
                key="upload"
                primaryText="Upload from file"
                secondaryText="Select a query save file to load"
                onClick={loadFileClick}
                statusIcon={<Publish className={classes.icon} />}
                actionIcon={<KeyboardArrowRight className={classes.icon} />}
            />
            <input type="file" ref={inputFile} onChange={readFile} style={{ display: 'none' }} />
        </Page>
    );
};

export default JobNewSelectPage;
