import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { CheckBoxOutlined, CheckBoxOutlineBlank, KeyboardArrowRight } from '@material-ui/icons';

import ListItem from 'components/ListItem';

const useStyles = makeStyles(() =>
    createStyles({
        icon: {
            fontSize: 32,
        },
    })
);

interface Props {
    completed: boolean;
    highlighted: boolean;
    primaryText: string;
    secondaryText: string;
    onClick?: () => void;
}

const ProgressListItem: React.FunctionComponent<Props> = ({
    completed,
    highlighted,
    primaryText,
    secondaryText,
    onClick,
}) => {
    const classes = useStyles();

    return (
        <ListItem
            primaryText={primaryText}
            secondaryText={secondaryText}
            onClick={onClick}
            style={{ backgroundColor: highlighted ? grey[200] : undefined }}
            statusIcon={
                completed ? (
                    <CheckBoxOutlined className={classes.icon} />
                ) : (
                    <CheckBoxOutlineBlank className={classes.icon} />
                )
            }
            actionIcon={<KeyboardArrowRight className={classes.icon} />}
        />
    );
};

export default ProgressListItem;
